
@charset "utf-8";
/*
#overview
base
*/

body{
	// background-image: url(../img/common/bg.png);
	// background-size: 192px auto;
	// background-position: center;
}

#wrapper{
	overflow-x: hidden;
}

#header {
	padding: 18px 0;
	position: relative;
	z-index: 100;
	@include tabletP{
		height: 60px;
	}

	.container{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		align-items:center;
		@include tabletP{
			display: block;
		}
	}

	.siteTitle{
		width: 246px;
		@include tabletP{
			width: 200px;
			margin-top: 3px;
		}
	}

	.gNavBtnMenu{
		position: absolute;
		top:0;
		right: 0;
		background-image: url(../img/common/btn_menu.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 30px 26px;
		width: 60px;
		height: 60px;
		display: none;
		@include tabletP{
			display: block;
		}
	}

}


#gNav{
	flex:1;
	@include tabletP{
		position: absolute;
		top:0;
		right: -50vw;
		width: 50vw;
		height: 100%;
		transition:all 0.2s ease;
		body.gnavopen &{
			right: 0;
		}
	}

	.btnClose{
		position: absolute;
		top:0;
		right: 0;
		background-image: url(../img/common/btn_close.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 24px 24px;
		width: 60px;
		height: 60px;
		display: none;
		@include tabletP{
			display: block;
		}
	}

	.overlay{
		background-color: nth($mainColor,1);
		width: 50vw;
		position: fixed;
		top:0;
		right: -50vw;
		bottom:0;
		opacity: 0.9;
		display: none;
		transition:all 0.2s ease;
		@include tabletP{
			display: block;
			body.gnavopen &{
				right: 0;
			}
		}
	}

	.inner{
		@include tabletP{
			position: relative;
			padding: 60px 20px 0;
		}
	}

	.nav{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		@include tabletP{
			display: block;
		}
	}

	li{

	}

	.item{
		&:not(:first-child){
			margin-left: 30px;
			@include tabletP{
				margin-left: 0;
			}
		}

		@include tabletP{
			border-bottom: solid 1px #fff;
		}

		&:first-child{
			@include tabletP{
				border-top: solid 1px #fff;
			}
		}

		a{
			display: block;
			position: relative;
			padding: 12px 0;
			text-decoration: none;
			text-align: center;
			font-weight: 500;
			@include fz(14);
			@include tabletP{
				text-align: left;
				color:#fff;
				@include fz(15);
			}

			&:after{
				content:"";
				transition:all 0.2s ease;
				position: absolute;
				left:0;
				right: 0;
				bottom:0;
				width: 0;
				height: 3px;
				background-color:nth($mainColor,1);
				margin: auto;
			}

			&:hover{
				color:nth($mainColor,1);
				opacity: 1;
				@include tabletP{
					color:#fff;
				}
				&:after{
					width:40px;
				}
			}

		}


		$gnav:service,staff,company,contact,data;

		@each $name in $gnav{
			##{$name} &.#{$name}{
				a{
					color:nth($mainColor,1);
					@include tabletP{
						color:#fff;
					}
					&:after{
						width:40px;
					}
		      }
		  }
		}

	}
}

#contents { }

.topicpath{
	@include fz(14);
	@include tabletP{
		@include fz(12);
	}

	* + &{
		margin-top: 15px;
	}

	ul{
		@include clearfix;
	}

	li{
		float:left;
		@include tabletP{
		}
		&:before{
			content:'\3E';
			margin: 0 4px;
			color:#333;
			@include tabletP{
			}
		}
	}

	a{
		color:#333;
		text-decoration: none;

		&:hover{
			text-decoration: underline;
		}
	}

	.top{
		&:before{
			display: none;
		}
	}
}//.topicpath


/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/

.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($containerPadding)+px;
	padding-left:  ($containerPadding)+px;

}

#footer {
	border-top: solid 1px nth($mainColor,1);

	.contact{
		padding: 70px 0;
		@include sphoneP{
			padding: 50px 0;
		}

		.container{
			display: flex;
			flex-wrap: wrap;
			justify-content:space-between;
			@include tabletP{
				display: block;
			}
		}

		.detail{
			width: 50%;
			@include tabletP{
				width: auto;
				margin-bottom: 20px;
			}

			.siteTitle{
				@include fz(20);
				font-weight: bold;
				@include sphoneP{
					@include fz(18);
				}
			}

			.tel{
				@include fz(24);
				font-weight: bold;
				line-height: 1.5;
				@include sphoneP{
					@include fz(18);
				}

				.free{
					@include fz(36);
					@include sphoneP{
						@include fz(26);
					}
				}

				.pause{
					margin: 0 10px;
					@include sphoneP{
						margin: 0 5px;
					}
				}

				a{
					text-decoration: none;
				}

			}

		}

		.btn{
			width: 50%;
			padding-left: 15px;
			@include tabletP{
				width: auto;
				padding-left: 0;
			}

		}

	}

	.main{
		background-color: nth($mainColor,1);
		padding: 60px 0 40px;
		color:#fff;
		@include tabletP{
		}

		a{
			color:#fff;
		}

		.container{
			position: relative;
		}
	}

	.pageTop{
		position: absolute;
		top:-82px;
		right: 12px;
		@include sphoneP{
			width: 50px;
		}
	}


	.fNav{
		display: table;
		width: 100%;
		margin-bottom: 40px;
		position: relative;
		@include tabletP{
			display: block;
			width: auto;
		}

		.privacy{
			position: absolute;
			bottom:0;
			right: 0;
			@include tabletP{
				text-align: center;
				position: static;
			}
		}

		.box{
			min-width:150px;
			display: table-cell;
			vertical-align: top;
			@include tabletP{
				display: block;
				min-width:0;
			}

			&.short{
				min-width:100px;
				@include tabletP{
					min-width:0;
				}
			}

			&:not(:first-child){
				padding-left: 20px;
				@include tabletP{
					padding-left: 0;
				}
			}

			&:not(:last-child){
				@include tabletP{
					margin-bottom: 40px;
				}
			}

			a{
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}

			dt{
				border-bottom: solid 1px #fff;
				margin-bottom: 15px;
				padding-bottom: 10px;
				@include fz(14);
			}

			li{
				position: relative;
				padding-left: 1em;
				@include fz(12);
				&:before{
					content:"\2D";
					position: absolute;
					top:0;
					left:0;
				}

				&:not(:last-child){
					margin-bottom: 3px;
				}
			}

		}
	}

	.bottom{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		border-top: solid 1px #fff;
		padding-top: 15px;
		@include fz(12);
		@include tabletP{
			display: block;
		}

		.link{
			@include clearfix;
			@include tabletP{
				margin-bottom: 20px;
				text-align: center;
			}

			@include sphoneP{
				@include fz(11);
			}

			li{
				float: left;
				line-height: 1.3;
				@include tabletP{
					float: none;
					display: inline-block;
				}

				&:not(:last-child){
					padding-right: 20px;
					position: relative;
					@include sphoneP{
					}
					&:after{
						content:"";
						background-color: #fff;
						width: 1px;
						position: absolute;
						top:0;
						right: 10px;
						bottom:0;
						margin: auto;
					}
				}
			}

			a{
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}

		.copyright{
			text-align: right;
			@include tabletP{
				text-align: center;
			}
		}
	}


}
