@charset "utf-8";
/*
#overview
modTable
*/

/*
#styleguide
.modTable

```
<table class="modTable">
	<tr class="modTable_r">
		<th class="modTable_h">仕事内容</th>
		<td class="modTable_d">録音された音声の文字起こし</td>
	</tr>
</table>
```
*/
.modTable{
	width: 100%;
	border-collapse: collapse;
	&_h,
	&_d{
		padding: 20px 0;
		vertical-align: top;
	}
	&_h{
		width: 200px;
		font-weight: bold;
		text-align: left;
		border-bottom: 1px solid #6eaedc;
	}
	&_d{
		padding-left: 10px;
		border-bottom: 1px solid #e2e2e2;
	}
	@include tabletP{
		&,
		& > tbody,
		&_r,
		&_h,
		&_d{
			display: block;
			width: auto;
		}
		&_h{
			padding-top: 0;
			padding-bottom: 10px;
		}
		&_d{
			padding-top: 10px;
			padding-left: 0;
			border-bottom: 0;
		}
	}
}
.modTable2{
	th{
		width: 20%;
	}
}