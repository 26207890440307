@charset "utf-8";

/*
#overview
modTxt
*/

/*
#styleguide
.modTxtStrong

```
<p class="modTxtStrong">下記条件を全て満たす方</p>
<p class="modTxtStrong04">下記条件を全て満たす方</p>
```
*/
.modTxtStrong{
	font-weight: bold;
	color: $colorRed;
	&04{
		font-weight:bold;
	}
}


/*
#styleguide
.modTxtStrong02

```
<p class="modTxtStrong02">下記条件を全て満たす方</p>
<p class="modTxtStrong02 modTxtStrong02_smallCenter">下記条件を全て満たす方</p>
<p class="modTxtStrong02 modTxtStrong02_smallLeft">下記条件を全て満たす方</p>
```
*/
.modTxtStrong02{
	text-align:center;
	font-weight:bold;
	@include fz(26);
	line-height:1.92;
	color:#00376a;
	@include sphoneP{
		@include fz(18);
		text-align:left;
	}
	&.modTxtStrong02_smallCenter{
		@include fz(20);
		text-align:center;
		@include sphoneP{
			@include fz(18);
		}
	}
	&.modTxtStrong02_smallLeft {
		font-size: 20px;
		font-size: 2rem;
		text-align: left;
		@include sphoneP{
			@include fz(18);
		}
	}
}

/*
#styleguide
.modTxtStrong03

```
<p class="modTxtStrong03">下記条件を全て満たす方</p>
```
*/
.modTxtStrong03{
	font-weight:bold;
	text-align:center;
	margin-bottom:20px;
	@include fz(20);
	@include sphoneP{
		@include fz(18);
	}

}

/*
#styleguide
.modTxtStrong

```
<p class="modAlignTxtleft">左寄せ</p>
<p class="modAlignTxtright">右寄せ</p>
<p class="modAlignTxtcenter">中央よせ</p>
```
*/
.modAlignTxt{
	&left{
		text-align:left;
	}
	&right{
		text-align:right;
	}
	&center{
		text-align:center;
	}
}
