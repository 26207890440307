@charset "utf-8";
/*
#overview
modBtn
*/

/*
#styleguide
modBtn01

```
<p><a href="#" class="modBtn01">ボタン</a></p>
<p><a href="#" class="modBtn01 modBtn01_inline">ボタン modBtn01_inline</a></p>
<p><a href="#" class="modBtn01 modBtn01_middle">ボタン modBtn01_middle</a></p>
<p><a href="#" class="modBtn01 modBtn01_large">ボタン modBtn01_large</a></p>
<p><a href="#" class="modBtn01 modBtn01_mail">ボタン modBtn01_mail</a></p>
<p><a href="#" class="modBtn01 modBtn01_mail modBtn01_mail_color01">ボタン modBtn01_mail_color01</a></p>
<p><a href="#" class="modBtn01 modBtn01_color02">ボタン modBtn01_color02</a></p>
<p><a href="#" class="modBtn01 modBtn01_color03">ボタン modBtn01_color03</a></p>
<p><a href="#" class="modBtn01 modBtn01_color04">ボタン modBtn01_color04</a></p>
<p><a href="#" class="modBtn01 modBtn01_anchor">ボタン modBtn01_anchor</a></p>
<p><a href="#" class="modBtn01 modBtn01_arrow">ボタン modBtn01_arrow</a></p>
```
*/

.modBtn01{
	display: block;
	transition:all 0.2s ease;
	width: 100%;
	padding: 15px 20px;
	border:none;
	background-color: nth($mainColor,1);
	border-radius:5px;
	color:#fff;
	text-decoration: none;
	font-weight: bold;
	text-align: center;
	@include fz(18);
	@include sphoneP{
		padding-top: 16px;
		padding-bottom: 16px;
		@include fz(17);
	}

	&:hover{
		opacity: 0.6;
	}

	&_large{
		padding-top: 35px;
		padding-bottom: 35px;
		@include sphoneP{
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}

	&_middle{
		@include fz(16);
		@include sphoneP{
			@include fz(14);
		}
	}

	&_color02{
		background-color: #e75e5e;
	}

	&_color03{
		background-color: #fff;
		color:#00376a;
	}

	&_color04 {
		background-color: #95CAF2;

	}

	&_inline{
		display: inline-block;
		width: auto;
		padding-right: 58px;
		padding-left: 58px;

		&.modBtn01_middle{
			padding-right: 30px;
			padding-left: 30px;
		}
	}


	&_mail{
		&:before{
			content:"";
			background-image: url(../img/common/icon_mail.svg);
			background-size: 30px 20px;
			background-position: 0 0;
			background-repeat: no-repeat;
			display: inline-block;
			width: 30px;
			height: 20px;
			margin-right: 16px;
			vertical-align: middle;
		}
		&_color01{
			background:#fff;
			color:#00376a;
			&:before{
				background-image: url(../img/common/icon_mail2.svg);
				background-size: 30px 20px;
			}
		}
	}

	&_anchor{
		position: relative;
		padding-left: 40px;
		padding-right: 40px;
		&:after{
			content:"";
			display: block;
			width: 16px;
			height: 10px;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			margin: auto 0;
			background: {
				image: url(../img/common/btn_anchor.svg);
				repeat: no-repeat;
				position: 0 0;
				size: 100% 100%;
			}
		}
	}

	&_arrow{
		position: relative;
		padding-left: 40px;
		padding-right: 40px;
		&:after{
			content:"";
			display: block;
			width: 8px;
			height: 14px;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 17px;
			margin: auto 0;
			background: {
				image: url(../img/common/btn_arrow.svg);
				repeat: no-repeat;
				position: 0 0;
				size: 100% 100%;
			}
		}
	}
	&.uploadnow {
		background: #ccc;
	}
	&.uploadnow:hover {
		opacity: 1;
	}
}//.modBtn01



/*
#styleguide
modBtnBox

```
<p class="modBtnBox"><button class="modBtn01 modBtn01_inline">ログイン</button></p>
```
*/
.modBtnBox{
	text-align: center;
	* + &{
		margin-top: 40px;
	}

}//.modBtnBox


/*
#styleguide
modBtnList

```

<ul class="modBtnList">
	<li><button class="modBtn01 modBtn01_middle">確認画面へ</button></li>
	<li><button class="modBtn01 modBtn01_color02 modBtn01_middle">リセット</button></li>
</ul>

```
*/
.modBtnList{
	display: flex;
	flex-wrap: wrap;
	justify-content:space-between;
	max-width:380px;
	margin-right: auto;
	margin-left: auto;

	* + &{
		margin-top: 70px;
		@include sphoneP{
			margin-top: 40px;
		}
	}

	li{
		width:48%;
	}


}//.modBtnList


/*
#styleguide
modBtnList02

```

<ul class="modBtnList02">
	<li><a href=""><span class="lilstIcon bgKeyword">キーワードで探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgConference">会議の種類から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgRemark">発言者から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgSearch">検索履歴から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgAssociation">連想語・類似文から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgCreate">発言集作成</span></a></li>
</ul>

```
*/
.modBtnList02{
	clear:both;
	overflow:hidden;

	li{
		width:32%;
		margin-right:2%;
		float:left;
		margin-bottom:2%;
		&:nth-child(3n){
			float:right;
			margin-right:0;
			@include tabletP{
				float:left;
			}
			@include sphoneP{
				float:none;
			}
		}
		&:nth-child(3n+1){
			clera:both;
			@include tabletP{
				clera:none;
			}
		}
		@include tabletP{
			width:48%;
			margin-right:0;
			&:nth-child(odd){
				clera:both;
			}
			&:nth-child(even){
				float:right;
			}
		}
		@include sphoneP{
			width:auto;
			float:none;
			&:nth-child(odd){
				clera:none;
			}
			&:nth-child(even){
				float:none;
			}
		}
		
			display:block;
			background:#daefff;
			color:#00376a;
			text-decoration:none;
			padding:0 25px;
			font-weight:bold;
			.lilstIcon{
				padding:25px 0 25px 60px;
				display:block;
				&.bgKeyword{
					background:url(../img/common/btn_keyword.png) left center no-repeat;
					background-size:30px auto;
				}
				&.bgConference{
					background:url(../img/common/btn_conference.png) left center no-repeat;
					background-size:37px auto;
				}
				&.bgRemark{
					background:url(../img/common/btn_remark.png) left center no-repeat;
					background-size:35px auto;
				}
				&.bgSearch{
					background:url(../img/common/btn_search.png) left center no-repeat;
					background-size:30px auto;
				}
				&.bgAssociation{
					background:url(../img/common/btn_association.png) left center no-repeat;
					background-size:36px auto;
				}
				&.bgCreate{
					background:url(../img/common/btn_create.png) left center no-repeat;
					background-size:34px auto;
				}
				@include tabletP{
					padding:20px 0 20px 45px;
				}
			}
			@include tabletP{
				padding:0 10px;
			}
		}


}//.modBtnList02