@charset "utf-8";

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
strong,
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}
input[type="submit"],button,label,select{
	cursor:pointer;
}

input[type="submit"],button{
	&:hover{
		
	}
}

.clearfix{
	@include clearfix;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* link
------------------------------------------------------------*/
a { 
	color: $baseLinkColor;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
		opacity:0.8;
	}
}

button:hover{
	opacity:0.8;
}

/* Fonts
------------------------------------------------------------*/

@font-face {
   font-family: 'Noto Sans Japanese';
   font-style: normal;
   font-weight: 300;
   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format('opentype');
}

@font-face {
   font-family: 'Noto Sans Japanese';
   font-style: normal;
   font-weight: 400;
   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format('opentype');
}

@font-face {
   font-family: 'Noto Sans Japanese';
   font-style: normal;
   font-weight: 500;
   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format('opentype');
 }

@font-face {
   font-family: 'Noto Sans Japanese';
   font-style: normal;
   font-weight: 700;
   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format('opentype');
 }


html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word; 
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
	//IE11以下は別のフォントを指定する場合
	// &.IEUnder{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
	@include fz;
	@include sphoneP{
		@include fz(14);
	}
}

input,button,textarea,select
{
	line-height:$baseLineHeight;
	color: $baseColor;
	font-family:$baseFontFamily;
	@include fz;
	//IE11以下は別のフォントを指定する場合
	// .IEUnder &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}


/* form
------------------------------------------------------------*/

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"],
select{
	padding:10px 18px;
	border-radius:0;
	background-color: #fff;
	border:solid 1px #dddddd;
	@include sphoneP{
		padding: 6px 10px;
	}
}

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"]{
	width:100%;
}

select{
	border-radius:5px;
	height: 48px;
	line-height:48px;

	@include sphoneP{
		height: auto;
		line-height: 1.5;
	}
}

$placeholderColor :#aaaaaa;

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"]{
	-webkit-appearance: none;

	&::-webkit-input-placeholder{
		color:$placeholderColor;
	}
	&::-moz-placeholder{
		color:$placeholderColor;
	}
	&:-ms-input-placeholder{
		color:$placeholderColor;
	}

	&.middle{
		width: 400px;
		max-width:100%;
	}
}

textarea.error,
input[type="text"].error,
input[type="tel"].error,
input[type="password"].error,
input[type="number"].error,
input[type="email"].error,
select.error{
	border-color: #e75e5e;
}


textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="email"].error{
	background-image: url(../img/common/icon_caution.svg);
	background-size:20px;
	background-position: right 10px center;
	background-repeat: no-repeat;
}


/* radio
-------------*/
input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 24px;
	cursor: pointer;
	width:100%;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
	content: '';
	top: 50%;
	position: absolute;
	border-radius: 100%;
	transition: all .2s;
}

input[type="radio"] + label::before {
	width: 16px;
	height: 16px;
	margin-top: -9px;
	left: 0;
	background: #fff;
	border: 1px solid #ccc;
}
input[type="radio"].error + label::before{
	border: 1px solid $colorStrong;
	background-color:rgba(255,242,242,1.00);
}
input[type="radio"] + label:hover::before {
	background: #FEFEFE;
}
input[type="radio"] + label::after {
	opacity: 0;
	left: 3px;
	width: 10px;
	height: 10px;
	margin-top: -6px;
	background: #626262;
	transform: scale(0.5);
}
input[type="radio"]:checked + label::before {
	background: #fff;
	// border: 1px solid $colorBlue;
}
input[type="radio"]:checked + label::after {
	opacity: 1;
	transform: scale(1);
}


/* checkbox
-------------*/
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 30px;
	cursor: pointer;
	-webkit-transition: all .2s;
	transition: all .2s;
}
input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
	position: absolute;
	content: '';
}
input[type="checkbox"] + label::before {
	top: calc(3px + 0.2em);
	left: 0;
	width: 20px;
	height: 20px;
	margin-top: 0;
	background: #fff;
	border: 1px solid #dddddd;
}
input[type="checkbox"] + label::after {
	opacity: 0;
	top: 0;
	left: 4px;
	width: 12px;
	height: 9px;
	margin-top: 8px;
	border-left: 3px solid #fff;
	border-bottom: 3px solid #fff;
	transform: rotate(-45deg) scale(0.5);
}
input[type="checkbox"] + label:hover::before {
	background: #fff;
}
input[type="checkbox"]:checked + label::before {
	background: nth($mainColor,1);
	border: 1px solid nth($mainColor,1);
}
input[type="checkbox"]:checked + label::after {
	opacity: 1;
	transform: rotate(-45deg) scale(1);
}


