@charset "utf-8";

// font
$baseFont: 16;
$baseLineHeight: 1.8;
$baseColor: #333;
$mainColor: #00376a,#58a1d7;
$baseLinkColor: nth($mainColor,1);

// color
$colorStrong: #E33E41; //強調系（エラーなど）
$colorBlue: #3498DB;
$colorGreen: #1ABC9C;
$colorRed: #f00;

// brake-point
$bpSphoneP: 600;
$bpTabletP: 768;
$bpTabletL: 992;

// container-padding
$containerPadding: 20;

// container-size
$container-sm: 750px;
$container-md: (940 + ($containerPadding*2) ) + px;
$container-lg: 1170px;


// font-family
$baseFontFamily : -apple-system,BlinkMacSystemFont,'Noto Sans Japanese',"Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$yugo: '游ゴシック', 'Yu Gothic', YuGothic, 'メイリオ', Meiryo, sans-serif;
$fontMincho : "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;
