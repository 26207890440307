@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
<ul class="modList01">
    <li>Illustrator に原寸で配置したサイズでの解像度が350dpiになるようにしてください。</li>
    <li> 拡大縮小率70%〜141％を超えると画質が劣化するのでご注意ください。</li>
</ul>
<ul class="modList02">
    <li>リストリストリストリストリストリストリストリスト<br>リストリストリストリストリストリスト</li>
    <li>リスト</li>
</ul>
<ul class="modList02 listFloat">
    <li>リスト<br>リストリストリストリストリストリスト</span></li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
</ul>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
	li{
		&:before {
			content: "\0030fb";
			margin-left:-2em;
			display: inline-block;
			width:2em;
			text-align:center;
		}
	}
}

ol.modListNormal li { list-style:decimal; }

ul.modList02{
	li{
		padding-left:17px;
		position:relative;
		@include fz(16);
		line-height:1.625;
		margin-bottom:10px;
		&:before{
			display:block;
			content:"";
			height:8px;
			width:8px;
			background:#00376a;
			position:absolute;
			top:10px;
			left:0;
		}
	}
	&.listFloat{
		overflow:hidden;
		li{
			float:left;
			width:23%;
			margin-right:2%;
			@include sphoneP{
				float:none;
				width:auto;
				margin-right:0;
			}
			@include tabletP{
				width:48%;
				margin-right:0;
			}
			&:nth-child(4n){
				float:right;
				margin-right:0;
				@include tabletP{
					float:left;
				}
				@include sphoneP{
					float:none;
				}
			}
			&:nth-child(4n+1){
				clear:both;
				@include tabletP{
					clear:none;
				}
			}
			&:nth-child(odd){
				@include tabletP{
					clear:both;
				}
			}
			&:nth-child(even){
				@include tabletP{
					float:right;
				}
				@include sphoneP{
					float:none;
				}
			}
		}
	}
}

/* indent */
.modIndent01{
	&.mod_ml30{
		margin-left: 30px;
	}
}
.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

/* フォーム
***************************************/
.modDlForm{
	margin:0 0 20px;
	
	dt{
		float:left;
		clear:both;
		width:16em;
		padding:33px 0 20px 63px;
		position:relative;
		
		@include tabletP{
			float:none;
			width:auto;
			padding:33px 0 0 63px;
		}
		&.required:before,
		&.any:before{
			position:absolute;
			left:0;
			top:33px;
			color:#FFF;
			@include fz(11);
			padding:2px 10px;
		}
		&.required:before{
			
			content:"必須";
			background:$colorStrong;
			
		}
		&.any:before{
			background:#999999;
			content:"任意";
		}
	}
	dd{
		border-bottom:solid 1px #ccc;
		padding:10px 0;
		padding-left:17em;
		
		@include tabletP{
			padding:10px 0;
		}
		.parts{
			padding:10px 0;
			textarea,
			input[type="text"],
			input[type="email"]
			{
				width:100%;
				
			}
			&.radio_inline,
			&.check_inline{
				>div{
					display:inline-block;
				}
			}
			&.name{
				display:flex;
				justify-content:space-between;
				>div{
					width:48.5%;
				}
				
			}
			&.post,&.tel{
				display:flex;
				.hyphen{
					padding:10px 10px 0 10px;
					
					@include sphoneP{
						padding:10px 5px 0 5px;
					}
				}
			}
			&.password{
				input{
					width:100%;
					max-width:300px;
					@include sphoneP{
						max-width:none;
					}
				}
				.text{
					padding:10px 0;
				}
			}
		}
	}
	
	.validationError{
		color:$colorStrong;
	}
}


/*
#styleguide
modColumn

```
<ul class="modColumn modColumnPc_3n modColumnTb_3n modColumnSp_3n">
    <li>3nリスト</li>
    <li>3nリスト</li>
    <li>3nリスト</li>
</ul>
<ul class="modColumn modColumnPc_2n modColumnTb_2n modColumnSp_2n">
    <li>2nリスト</li>
    <li>2nリスト</li>
</ul>
```
*/
.modColumn{
	@include clearfix;
	> li{
		float: left;
	}
	@include tabletPMin{
		&Pc{
			@include modColumn;
		}
	}
	@include tabletP{
		@include sphonePMin{
			&Tb{
				@include modColumn;
			}
		}
	}
	@include sphoneP{
		&Sp{
			@include modColumn;
		}
	}
}


/*
#styleguide
modListBg

```
<div class="modListBg">
  <ol class="modListBg_list">
    <li><span>国語力のある方。基本的な語彙力、文章力をお持ちの方</span></li>
    <li><span>細かい作業が得意な方</span></li>
    <li><span>根気・やる気があり、仕事に対して最後まで責任を持てる方</span></li>
    <li><span>時間管理・自己管理ができる方</span></li>
    <li><span>時事問題や社会情勢等に興味関心を抱き、向上心・探究心のある方</span></li>
    <li><span>守秘義務(知り得た個人情報を第三者に漏えいしない)を遵守できる方</span></li>
  </ol>
</div>
```
*/
.modListBg{
	padding: 40px;
	background: #e5f4ff;
	@include sphoneP{
		padding: 20px;
	}
	&_list{
		display: table;
		counter-reset: bgList;
		> li{
			display: table-row;
			counter-increment: bgList;
			&:before,
			> div,
			> span{
				display: table-cell;
			}
			&:before{
				content: counter(bgList) ".";
				font-weight: bold;
				white-space: nowrap;
				padding-right: 10px;
			}
			&:nth-child(n+2){
				&:before,
				> span{
					padding-top: 5px;
				}
			}
		}
	}
}


/*
#styleguide
modListStep

```
<ol class="modListStep">
  <li>
    <div class="modListStep_num">Step<span>1</span></div>
    <div class="modListStep_content">
      <p class="modListStep_title">エントリー</p>
      <div class="modListStep_txt">
        <p class="modListStep_phrase">応募フォームに必要事項を入力の上、送信してください。<br>
      選考後、合格の方には技能試験のご案内をメール送信いたします。</p>
      </div>
    </div>
  </li>
  <li>
    <div class="modListStep_num">Step<span>2</span></div>
    <div class="modListStep_content">
      <p class="modListStep_title">技能試験</p>
      <div class="modListStep_txt">
        <p class="modListStep_phrase">お手持ちのパソコンを使用して、ご自宅で技能試験を受けていただきます。<br>
      合格の方には、仕事内容についてメール送信いたします。</p>
      </div>
    </div>
  </li>
</ol>
```
*/
.modListStep{
	> li{
		position: relative;
		padding: 40px;
		background: #fff;
		@include clearfix;
		@include tabletP{
			padding: 20px;
		}
		&:nth-child(n+2){
			margin-top: 30px;
			&:before{
				position: absolute;
				top: -24px;
				left: 0;
				right: 0;
				content: "";
				width: 200px;
				height: 30px;
				margin: 0 auto;
				background: url(../img/common/arrow1.png) no-repeat left top;
				background-size: 100%;
			}
		}
		.modListStep_phrase{
				&+.modListStep_phrase{
				margin-top:25px;
			}
		}
	}
	&_num{
		float: left;
		margin-right: 35px;
		width: 110px;
		min-height: 110px;
		padding-top: 24px;
		@include fz(30);
		font-weight: bold;
		color: #fff;
		line-height: 1.082;
		background: #00376a;
		text-align: center;
		border-radius: 110px;
		> span{
			display: block;
		}
		@include sphoneP{
			margin-right: 10px;
			width: 50px;
			min-height: 50px;
			padding-top: 10px;
			@include fz(16);
		}
	}
	&_content{
		overflow: hidden;
		@include sphoneP{
			overflow: visible;
		}
	}
	&_title{
		margin-bottom: 15px;
		@include fz(30);
		font-weight: bold;
		line-height: 1.2;
		color: #00376a;
		@include sphoneP{
			padding-top: 12px;
			@include fz(20);
		}
	}
	&_txt{
		@include sphoneP{
			clear: both;
			padding-top: 10px;
		}
	}
}


/*
#styleguide
modIconList
```
<ul class="modIconList">
  <li>
    <div class="modIconList_icon"><img src="../assets/img/common/icon_camera.png" alt=""></div>
    <div class="modIconList_txt">
      <p class="modIconList_title j_match">マニュアルなしでも簡単・迅速にカメラ・マイクなどの議場設備一括操作</p>
      <div class="modIconList_comment">
        <p>カメラ・マイクの一括操作から議会映像配信まで、マニュアルなしでも直感的に操作が可能です。</p>
      </div>
    </div>
  </li>
  <li>
    <div class="modIconList_icon"><img src="../assets/img/common/icon_earth.png" alt=""></div>
    <div class="modIconList_txt">
      <p class="modIconList_title j_match">議会中継システムと連動で簡単インターネット配信</p>
      <div class="modIconList_comment">
        <p>高性能エンコーダー内蔵で、議会中継システムDiscussVisionSmartと連動しインターネット配信を簡単に実現。庁内向け同時配信も可能。放送用SDIボード採用で、複数のカメラでも最高品質の映像が提供できます。</p>
      </div>
    </div>
  </li>
  <li>
    <div class="modIconList_icon"><img src="../assets/img/common/icon_price.png" alt=""></div>
    <div class="modIconList_txt">
      <p class="modIconList_title j_match">機器を集約導入コスト、<br class="modPcOnly">ランニングコストを低減</p>
      <div class="modIconList_comment">
        <p>エンコーダー、カメラコントローラーなどの機器を内蔵しているため、導入時のイニシャルコストだけではなく、ランニングコスト低減により、導入後の継続的なコストダウンが可能!</p>
      </div>
    </div>
  </li>
</ul>
```
*/
.modIconList{
	display: flex;
	justify-content: space-between;
	@include tabletP{
		display: block;
	}
	> li{
		overflow:hidden;
		width: 31.17%;
		padding: 30px 20px;
		background: #fff;
		@include tabletP{
			width: auto;
			&:nth-child(n+2){
				margin-top: 10px;
			}
		}
	}
	&_icon{
		margin-bottom: 20px;
		text-align: center;
		@include tabletP{
			float: left;
			margin: 0 20px 0 0;
		}
		@include sphoneP{
			float: none;
			margin: 0 0 20px;
		}
		img{
			width:110px;
		}
	}
	&_title{
		margin-bottom: 10px;
		@include fz(20);
		font-weight: bold;
		line-height: 1.6;
		text-align: center;
		color: #00376a;
		@include tabletP{
			text-align: left;
		}
		@include sphoneP{
			@include fz(18);
		}
	}
	&_txt{
		overflow: hidden;
	}
}



/*
#styleguide
modDetailsList
```
<ul class="modDetailsList">
	<li>
		<p class="listTitle">超高速全文検索エンジンを搭載して、スピードアップ!</p>
		<div class="listText">
			<ul class="list01">
				<li>・高速全文検索エンジン(EFS)を搭載、膨大な会議録データでも超高速に検索</li>
				<li>・思いついた言葉を入力するだけですぐさま検索を実行</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">議会用語を含む約17万語のシソーラス辞書を搭載</p>
		<div class="listText">
			<ul class="list01">
				<li>・17万語から成るシソーラス辞書(関連語辞書)を標準搭載</li>
				<li>・会議録には絶対不可欠となる口語体文書に威力を発揮</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">連想語・類似文検索機能</p>
		<div class="listText">
			<p class="text01">NTT研究所開発の日本語検索処理技術により、効果的な利用が可能</p>
			<ul class="list01">
				<li>・話題語抽出 - 話題性の高いワードランキングの表示</li>
				<li>・連想語検索 - 曖昧なキーワード検索でも連想語を表示</li>
				<li>・類似文検索 - 過去の会議録から類似事例を抽出</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">外部リンクとの対応</p>
		<div class="listText">
			<ul class="list01">
				<li>・例規情報システム「REIKI-BASE」との連携</li>
				<li>・政務調査管理システム「Power Finder」との連携によりスピーディーに情報、資料を収集可能</li>
				<li>・外部リンクの設定も可能になり関連ドキュメントを素早く入手</li>
			</ul>
		</div>
	</li>
</ul>

<ul class="modDetailsList listColor02">
	<li>
		<p class="listTitle">超高速全文検索エンジンを搭載して、スピードアップ!</p>
		<div class="listText">
			<ul class="list01">
				<li>・高速全文検索エンジン(EFS)を搭載、膨大な会議録データでも超高速に検索</li>
				<li>・思いついた言葉を入力するだけですぐさま検索を実行</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">議会用語を含む約17万語のシソーラス辞書を搭載</p>
		<div class="listText">
			<ul class="list01">
				<li>・17万語から成るシソーラス辞書(関連語辞書)を標準搭載</li>
				<li>・会議録には絶対不可欠となる口語体文書に威力を発揮</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">連想語・類似文検索機能</p>
		<div class="listText">
			<p class="text01">NTT研究所開発の日本語検索処理技術により、効果的な利用が可能</p>
			<ul class="list01">
				<li>・話題語抽出 - 話題性の高いワードランキングの表示</li>
				<li>・連想語検索 - 曖昧なキーワード検索でも連想語を表示</li>
				<li>・類似文検索 - 過去の会議録から類似事例を抽出</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">外部リンクとの対応</p>
		<div class="listText">
			<ul class="list01">
				<li>・例規情報システム「REIKI-BASE」との連携</li>
				<li>・政務調査管理システム「Power Finder」との連携によりスピーディーに情報、資料を収集可能</li>
				<li>・外部リンクの設定も可能になり関連ドキュメントを素早く入手</li>
			</ul>
		</div>
	</li>
</ul>
```
*/
.modDetailsList{
	overflow:hidden;
	> li{
		width:49%;
		margin-bottom:25px;
		@include tabletP{
			width:auto;
		}
		&:nth-child(odd){
			float:left;
			clear:both;
			@include tabletP{
				float:none;
			}
		}
		&:nth-child(even){
			float:right;
			@include tabletP{
				float:none;
			}
		}
		.listTitle{
			font-weight:bold;
			@include fz(18);
			text-align:center;
			background:#daefff;
			padding:10px;
			margin-bottom:10px;
		}
		.list01{
			li{
				margin-bottom:5px;
				text-indent:-1em;
				padding-left:1em;
			}
		}
		.text01{
			margin-bottom:10px;
		}
	}
	&.listColor02{
		.listTitle{
			background:#fff;
		}
	}
}

.modSitemapList{
	line-height:2rem;
	width: 80%;
	margin:0 auto;
	@include tabletP{
		width: auto;
	}
	li{
		p{
			border-left: solid 5px #00376a;
			margin-bottom: 10px;
			
			a{
				padding-left: 15px;
				@include fz(20);
			}
		}
	}
		ol{
			margin-left: 20px;
			margin-bottom: 40px;
			li{
				border-bottom: dashed 1px #ccc;
			}
		}
	a{
		text-decoration: none;
		display: block;
		padding:10px 5px;
		&:hover{
			background: #efefef;

		}
	}
	@include fz(16);
	@include sphoneP{

	}
}