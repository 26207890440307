@charset "utf-8";

/*
#overview
modForm
*/

/*
#styleguide
xxxxx

```
コード
```
*/
.form_attention {
	font-size: 14px;
	font-size: 1.4rem;
	color: #666;
}

.modFormLead{
	margin-bottom: 50px;
	&.modFormLead_red{
		color:#ff0000;
		strong{
			font-weight:bold;
		}
	}
}//.modFormLead

.modFormList{
	background-color: #daefff;
	padding: 50px 40px;
	@include sphoneP{
		padding: 15px;
	}

	& + .modFormList{
		margin-top: 50px;
	}

	.title{
		margin-bottom: 30px;
		font-weight: bold;
		@include fz(22);
		text-align: center;
		@include sphoneP{
			@include fz(18);
		}
	}

	>.item{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		@include sphoneP{
			display: block;
		}

		&:not(:last-child){
			border-bottom: solid 1px #fff;
			padding-bottom: 30px;
			margin-bottom: 30px;
			@include sphoneP{
				padding-bottom: 15px;
				margin-bottom: 15px;
			}
		}

		&.vCenter{
			align-items:center;
		}

		>dt{
			width: 270px;
			padding-right: 75px;
			position: relative;
			font-weight: bold;
			@include tabletP{
				width:30%;
			}

			@include sphoneP{
				width: auto;
				margin-bottom: 10px;
			}

			&.noRequired{
				padding-right: 0;
			}

			.required{
				background-color: #e75e5e;
				position: absolute;
				top:0;
				right: 12px;
				display: inline-block;
				width: 60px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				color:#fff;
				font-weight: 500;
				@include sphoneP{
					top:0;
					right: 0;
					width: 50px;
					height: 25px;
					line-height: 25px;
				}
			}

			.sub{
				display: block;
				@include fz(14);
				@include sphoneP{
					@include fz(12);
				}
			}

			.br{
				display: block;
				@include tabletP{
					display:inline;
				}
			}

		}

		&.vCenter{
			>dt{

				.required{
					bottom:0;
					margin: auto;
				}
			}
		}

		>dd{
			flex:1;

		}
	}

}//.modFormList


.modFormCalendar{
	position: relative;
	max-width:330px;
	padding-right: 60px;


	input{
		width: 280px;
		max-width:100%;
	}


	.btnCalendar{
		display: block;
		background-image: url(../img/common/icon_calendar.svg);
		background-position:center;
		background-repeat: no-repeat;
		background-size: 28px 31px;
		width: 60px;
		height: 40px;
		position: absolute;
		top:0;
		right: 0;
		bottom:0;
		margin: auto;
		@include sphoneP{
			background-size: 20px 22px;
		}
	}

}//.modFormCalendar

.modFormZip{
	display: flex;
	flex-wrap: wrap;
	align-items:center;
	@include sphoneP{
		justify-content:space-between;
	}

	.input{
		width: 134px;
		@include sphoneP{
			width:60%;
		}
	}

	.btn{
		padding-left: 20px;
		width: 160px;
		@include sphoneP{
			width: auto;
			flex:1;
			padding-left: 10px;
		}

		.modBtn01{
			padding-top: 4px;
			padding-bottom: 6px;
			@include fz(16);
			@include sphoneP{
				@include fz(14);
			}
		}

	}

}//.modFormZip

.modFormCheckList{

	li{
		&:not(:last-child){
			margin-bottom: 5px;
		}
	}

	.checkInput{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;

		.check,.input{
		}


		.check{
			width:6em;

		}

		.input{
			flex:1;

			input{
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}

	}

}//.modFormCheckList

.modFormRadioList{
	@include clearfix;

	li{
		float: left;
		&:not(:last-child){
			margin-left: 10px;
		}
	}

}//.modFormRadioList

.modFormTime{
	display: flex;
	flex-wrap: wrap;
	align-items:center;


	.input{
		width: 100px;
	}

	.txt{
		margin-left: 15px;
	}

}//.modFormTime

.modFormError{
	margin-top: 8px;
	color:#e75e5e;
}//.modFormError

.modFormBirthday{
	display: flex;
	flex-wrap: wrap;
	align-items:center;
	@include sphoneP{
		justify-content:space-between;
	}

	li{

	}

	.year{
		width: 110px;
		@include sphoneP{
			width:30%;
		}
	}

	.month,
	.date{
		width: 90px;
		@include sphoneP{
			width:20%;
		}
	}

	.txt{
		width:1.5em;
		padding-left: 9px;
		@include sphoneP{
			width:10%;
			padding: 0 5px;
			text-align: center;
		}
		& + .input{
			margin-left: 20px;
			@include sphoneP{
				margin-left: 0;
			}
		}
	}

}//.modFormBirthday

.modFormConfBlock{
	border-radius:25px;
	border:solid 10px #dcdcdc;
	padding: 30px;
	margin: 80px 0;
	@include sphoneP{
		border-radius:15px;
		border-width: 5px;
		padding: 15px;
		margin-bottom: 50px;
	}

	.wrapper{
		padding-right: 30px;
		padding-left: 30px;
		@include sphoneP{
			padding-right: 0;
			padding-left: 0;
		}

		& + .wrapper{
			border-top: solid 1px #cfcfcf;
			padding-top: 30px;
			margin-top: 40px;
			@include sphoneP{
				padding-top: 15px;
				margin-top: 20px;
			}
		}

		.confTitle{
			margin-bottom: 20px;
			font-weight: bold;
			@include fz(20);
			@include sphoneP{
				margin-bottom: 15px;
				@include fz(18);
			}
		}

		.wrapperInner{
			margin-left: 1.2em;
		}
	}


	table{
		@include sphoneP{
			display: block;
		}

		tbody,tr,th,td{
			@include sphoneP{
				display: block;
			}
		}

		th{
			width: 6em;
			font-weight: bold;
			@include sphoneP{
				width: auto;
			}
		}

		td{
			color:#666666;
		}


		th,td{
			padding-bottom: 15px;
			vertical-align: top;
		}
	}
	.table2{
		th{
			width: 30%;
			padding-right:20px;
			@include sphoneP{
				width: auto;
			}
		}
	}
}//.modFormConfBlock
.resultsLabel {
	@include sphoneP{
		font-size: 16px;
		font-size: 1.6rem;
	}
}
.modFormZip,
.modFormTime{
input[type='number'] {
    -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
}