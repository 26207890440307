@charset "utf-8";

/*
#overview
modBlock
*/

/*
#styleguide

```
<div class="modBlockInner01">上下にpaddingがつく</div>
```
*/

.modBlockInner01{
	padding: 50px 0 70px;
	@include sphoneP{
		padding-top: 30px;
		padding-bottom: 40px;
	}
}//.modBlockInner01

/*
#styleguide
.modBlockTrim
```
<div class="modBlockInner01">
	<div class="modBlockTrim">modBlockInner01の下余白を内側から消す</div>
</div>
```
*/

.modBlockTrim{
	margin-bottom: -70px;
	@include sphoneP{
		margin-bottom: -40px;
	}
}//.modBlockTrim


.modBlockLogin{
	&Body{
		padding: 40px 60px 50px;
		margin: 0 auto;
		max-width:524px;
		background-color: #daefff;
		@include sphoneP{
			padding: 20px;
		}

		* + &{
			margin-top: 60px;
			@include sphoneP{
				margin-top: 40px;
			}
		}

		.form{
			dt{
				margin-bottom: 5px;
				font-weight: bold;
			}

			dd{
				& + dt{
					margin-top:20px;
					@include sphoneP{
					}
				}
			}
		}

		.saveCheck{
			margin-top: 15px;
			@include sphoneP{
			}
		}

		.policy{
			border-top: solid 1px #fff;
			padding-top: 40px;
			margin-top: 40px;
			@include sphoneP{
				padding-top: 20px;
				margin-top: 20px;
			}
			dt{
				margin-bottom: 10px;
				font-weight: 500;

				.required{
					display: inline-block;
					background-color: #e75e5e;
					padding: 3px 15px 5px;
					margin-right: 10px;
					line-height: 1.5;
					color:#fff;
					@include sphoneP{
						padding: 2px 6px 3px;
					}
				}
			}
		}
	}//body

	&Reminder{
		margin-top: 100px;
		text-align: center;
		@include sphoneP{
			margin-top: 50px;
		}
	}


}//.modBlockLogin



/*
#styleguide
.modMargin
```
<div class="modMargin">下方向の共通余白</div>
```
*/
.modMargin{
	margin-bottom: 80px;
	@include tabletP{
		margin-bottom: 40px;
	}
}



/*
#styleguide
.modImgBlock
```
<div class="modImgBlock">
  <div class="modImgBlock_txt">
    <h3 class="modTitle02">文字起こしってどんな仕事？</h3>
    <div class="modImgBlock_comment">
      <p>録音された音声を足踏みの再生機を使用して聞きながら、パソコンで文字（日本語）に起こしていただく仕事です。<br>
      内容は、地方議会、各種会議、講演会、インタビュー等、多岐にわたります。<br>
      今現在の社会情勢や情報を生の声で聞くことができます。</p>
    </div>
  </div>
  <div class="modImgBlock_image"><img src="../assets/img/recruit/recruit_img1.jpg" alt=""></div>
</div>
```
*/
.modImgBlock{
	@include clearfix;
	&_txt{
		float: left;
		width: 44.5%;
		@include tabletP{
			margin-bottom: 20px;
		}
	}
	&_image{
		float: right;
		width: 51.064%;
		text-align: center;
	}
	&_txt,
	&_image{
		@include tabletP{
			float: none;
			width: auto;
		}
	}
}



/*
#styleguide
.modBgBlock
```
<div class="modBgBlock"></div>
<div class="modBgBlock colorType02"></div>
```
*/
.modBgBlock{
	position: relative;
	padding: 70px 0 80px;
	background: #e5f4ff;
	z-index: 1;
	@include tabletP{
		padding: 40px 0;
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: -10000px;
		right: -10000px;
		width: 10000px;
		height: 100%;
		margin: 0 auto;
		background: #e5f4ff;
		z-index: -1;
	}
	&_thin{
		position: relative;
		padding: 70px 0 80px;
		background: #f4faff;
		z-index: 1;
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: -10000px;
			right: -10000px;
			width: 10000px;
			height: 100%;
			margin: 0 auto;
			background: #f4faff;
			z-index: -1;
		}
	}
}

/*
#styleguide
.modContactBlock01
```

<div class="modContactBlock01">
	<div class="modContactBlock01In">
		<div class="detail">
			<p class="siteTitle">株式会社議事録発行センター</p>
			<p class="tel"><span class="free modTelLink">0120-62-1332</span><span class="pause">/</span><a href="tel:0862551332">086-255-1332</a></p>
			<p>平日 8:30～17:20 (土日祝日は除く)</p>
		</div>
		<div class="btn">
			<a href="#" class="modBtn01 modBtn01_large modBtn01_mail modBtn01_mail_color01">お問い合わせフォームへ</a>
		</div>
	</div>
</div>
```
*/
.modContactBlock01{
	padding: 30px 0 50px 0;
	position: relative;
	color:#fff;
	margin-top: 100px;
	//
	&:before{
		content: "";
		position: absolute;
		top: 0;
		//left: -10000px;
		//right: -10000px;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		background: #00376a;
		z-index: -1;
	}
	@include sphoneP{
		padding: 20px 0 0 0;
		margin-top: 50px;
	}

	.modContactBlock01In{
		display: flex;
		flex-wrap: wrap;
		justify-content:space-between;
		padding:0 50px;
		@include tabletP{
			display: block;
			padding:30px;
		}
		.detail{
			width: 60%;
			@include tabletP{
				width: auto;
				margin-bottom: 20px;
			}

			.siteTitle{
				@include fz(20);
				font-weight: bold;
				@include sphoneP{
					@include fz(18);
				}
			}

			.tel{
				@include fz(24);
				font-weight: bold;
				line-height: 1.5;
				@include sphoneP{
					@include fz(18);
					display: block;
				}

				.free{
					@include fz(36);
					@include sphoneP{
						@include fz(26);
						display: block;
						
					}
				}

				.pause{
					margin: 0 10px;
					@include sphoneP{
						display: none;
						
					}
				}

				a{
					text-decoration: none;
					color:#fff;
				}

			}

		}

		.btn{
			width: 40%;
			padding-left: 15px;
			@include tabletP{
				width: auto;
				padding-left: 0;
			}

		}

	}

}



/*
#styleguide
.modBgBlock02
```
<div class="modBgBlock02">背景#eeeeee</div>
```
*/
.modBgBlock02{
	background:#eeeeee;
	padding:30px;
	@include sphoneP{
		padding:30px 20px;
	}
}