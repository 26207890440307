@charset "utf-8";

@mixin tabletP {
  //タブレット縦の場合
  @media only screen and (max-width: $bpTabletL + px) {
	  @content;
  }
}
@mixin tabletPMin {
  @media only screen and (min-width: #{$bpTabletL + 1px}) {
    @content;
  }
}

@mixin sphoneP {
  //スマートフォン縦の場合
  @media only screen and (max-width: $bpSphoneP + px) {
	  @content;
  }
}
@mixin sphonePMin {
  @media only screen and (min-width: #{$bpSphoneP + 1px}) {
    @content;
  }
}

// webfont icon
@mixin iconFont() {
	font-family: FontAwesome;
	line-height:1;
}

// clearfix
@mixin clearfix() {
 &:after {
 content:".";
 display:block;
 clear:both;
 height:0;
 visibility:hidden;
}
}

// font-size
@mixin fz($size: $baseFont,$important:false) {
 font-size: if($important, $size + px!important, $size + px);
 font-size: if($important, ($size / 10) + rem!important, ($size / 10) + rem);
}

// modColumn
@mixin modColumn{
	$columnWidth: 48.936%, 31.17%;
	&_1n > li{
		float: none;
	}
	&_2n > li{
		float: right;
		width: nth($columnWidth,1);
		&:nth-child(2n+1){
			clear: both;
			float: left;
		}
	}
	&_3n > li{
		width: nth($columnWidth,2);
		margin-left: 3.245%;
		&:nth-child(3n+1){
			clear: both;
			margin-left: 0;
		}
	}
}