/* Reset
------------------------------------------------------------*/

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

address,
em {
	font-style: normal;
}

strong,
th {
	font-weight: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

th {
	text-align: left;
}

hr,
legend {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}

img,
fieldset {
	border: 0;
}

img {
	border: 0;
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

li {
	list-style-type: none;
}

input[type="submit"],
button,
label,
select {
	cursor: pointer;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* link
------------------------------------------------------------*/

a {
	color: #00376a;
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
	opacity: 0.8;
}

button:hover {
	opacity: 0.8;
}

/* Fonts
------------------------------------------------------------*/

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 300;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format("opentype");
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 400;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format("opentype");
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 500;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 700;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format("opentype");
}

html {
	font-size: 62.5%;
}

/* 10px base */

body {
	-webkit-text-size-adjust: 100%;
	word-wrap: break-word;
	overflow-wrap: break-word;
	font-family: -apple-system, BlinkMacSystemFont, "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	line-height: 1.8;
	color: #333;
	font-size: 16px;
	font-size: 1.6rem;
}

input,
button,
textarea,
select {
	line-height: 1.8;
	color: #333;
	font-family: -apple-system, BlinkMacSystemFont, "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-size: 16px;
	font-size: 1.6rem;
}

/* form
------------------------------------------------------------*/

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"],
select {
	padding: 10px 18px;
	border-radius: 0;
	background-color: #fff;
	border: solid 1px #dddddd;
}

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"] {
	width: 100%;
}

select {
	border-radius: 5px;
	height: 48px;
	line-height: 48px;
}

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"] {
	-webkit-appearance: none;
}

textarea::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder {
	color: #aaaaaa;
}

textarea::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder {
	color: #aaaaaa;
}

textarea:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder {
	color: #aaaaaa;
}

textarea.middle,
input[type="text"].middle,
input[type="tel"].middle,
input[type="password"].middle,
input[type="number"].middle,
input[type="email"].middle {
	width: 400px;
	max-width: 100%;
}

textarea.error,
input[type="text"].error,
input[type="tel"].error,
input[type="password"].error,
input[type="number"].error,
input[type="email"].error,
select.error {
	border-color: #e75e5e;
}

textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="email"].error {
	background-image: url(../img/common/icon_caution.svg);
	background-size: 20px;
	background-position: right 10px center;
	background-repeat: no-repeat;
}

/* radio
-------------*/

input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 24px;
	cursor: pointer;
	width: 100%;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
	content: '';
	top: 50%;
	position: absolute;
	border-radius: 100%;
	transition: all .2s;
}

input[type="radio"] + label::before {
	width: 16px;
	height: 16px;
	margin-top: -9px;
	left: 0;
	background: #fff;
	border: 1px solid #ccc;
}

input[type="radio"].error + label::before {
	border: 1px solid #E33E41;
	background-color: #fff2f2;
}

input[type="radio"] + label:hover::before {
	background: #FEFEFE;
}

input[type="radio"] + label::after {
	opacity: 0;
	left: 3px;
	width: 10px;
	height: 10px;
	margin-top: -6px;
	background: #626262;
	transform: scale(0.5);
}

input[type="radio"]:checked + label::before {
	background: #fff;
}

input[type="radio"]:checked + label::after {
	opacity: 1;
	transform: scale(1);
}

/* checkbox
-------------*/

input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 30px;
	cursor: pointer;
	-webkit-transition: all .2s;
	transition: all .2s;
}

input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
	position: absolute;
	content: '';
}

input[type="checkbox"] + label::before {
	top: calc(3px + 0.2em);
	left: 0;
	width: 20px;
	height: 20px;
	margin-top: 0;
	background: #fff;
	border: 1px solid #dddddd;
}

input[type="checkbox"] + label::after {
	opacity: 0;
	top: 0;
	left: 4px;
	width: 12px;
	height: 9px;
	margin-top: 8px;
	border-left: 3px solid #fff;
	border-bottom: 3px solid #fff;
	transform: rotate(-45deg) scale(0.5);
}

input[type="checkbox"] + label:hover::before {
	background: #fff;
}

input[type="checkbox"]:checked + label::before {
	background: #00376a;
	border: 1px solid #00376a;
}

input[type="checkbox"]:checked + label::after {
	opacity: 1;
	transform: rotate(-45deg) scale(1);
}

/*
#overview
base
*/

#wrapper {
	overflow-x: hidden;
}

#header {
	padding: 18px 0;
	position: relative;
	z-index: 100;
}

#header .container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

#header .siteTitle {
	width: 246px;
}

#header .gNavBtnMenu {
	position: absolute;
	top: 0;
	right: 0;
	background-image: url(../img/common/btn_menu.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 30px 26px;
	width: 60px;
	height: 60px;
	display: none;
}

#gNav {
	flex: 1;
}

#gNav .btnClose {
	position: absolute;
	top: 0;
	right: 0;
	background-image: url(../img/common/btn_close.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	width: 60px;
	height: 60px;
	display: none;
}

#gNav .overlay {
	background-color: #00376a;
	width: 50vw;
	position: fixed;
	top: 0;
	right: -50vw;
	bottom: 0;
	opacity: 0.9;
	display: none;
	transition: all 0.2s ease;
}

#gNav .nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

#gNav .item:not(:first-child) {
	margin-left: 30px;
}

#gNav .item a {
	display: block;
	position: relative;
	padding: 12px 0;
	text-decoration: none;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	font-size: 1.4rem;
}

#gNav .item a:after {
	content: "";
	transition: all 0.2s ease;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 0;
	height: 3px;
	background-color: #00376a;
	margin: auto;
}

#gNav .item a:hover {
	color: #00376a;
	opacity: 1;
}

#gNav .item a:hover:after {
	width: 40px;
}

#service #gNav .item.service a {
	color: #00376a;
}

#service #gNav .item.service a:after {
	width: 40px;
}

#staff #gNav .item.staff a {
	color: #00376a;
}

#staff #gNav .item.staff a:after {
	width: 40px;
}

#company #gNav .item.company a {
	color: #00376a;
}

#company #gNav .item.company a:after {
	width: 40px;
}

#contact #gNav .item.contact a {
	color: #00376a;
}

#contact #gNav .item.contact a:after {
	width: 40px;
}

#data #gNav .item.data a {
	color: #00376a;
}

#data #gNav .item.data a:after {
	width: 40px;
}

.topicpath {
	font-size: 14px;
	font-size: 1.4rem;
}

* + .topicpath {
	margin-top: 15px;
}

.topicpath ul:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.topicpath li {
	float: left;
}

.topicpath li:before {
	content: '\3E';
	margin: 0 4px;
	color: #333;
}

.topicpath a {
	color: #333;
	text-decoration: none;
}

.topicpath a:hover {
	text-decoration: underline;
}

.topicpath .top:before {
	display: none;
}

/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/

.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
}

#footer {
	border-top: solid 1px #00376a;
}

#footer .contact {
	padding: 70px 0;
}

#footer .contact .container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

#footer .contact .detail {
	width: 50%;
}

#footer .contact .detail .siteTitle {
	font-size: 20px;
	font-size: 2rem;
	font-weight: bold;
}

#footer .contact .detail .tel {
	font-size: 24px;
	font-size: 2.4rem;
	font-weight: bold;
	line-height: 1.5;
}

#footer .contact .detail .tel .free {
	font-size: 36px;
	font-size: 3.6rem;
}

#footer .contact .detail .tel .pause {
	margin: 0 10px;
}

#footer .contact .detail .tel a {
	text-decoration: none;
}

#footer .contact .btn {
	width: 50%;
	padding-left: 15px;
}

#footer .main {
	background-color: #00376a;
	padding: 60px 0 40px;
	color: #fff;
}

#footer .main a {
	color: #fff;
}

#footer .main .container {
	position: relative;
}

#footer .pageTop {
	position: absolute;
	top: -82px;
	right: 12px;
}

#footer .fNav {
	display: table;
	width: 100%;
	margin-bottom: 40px;
	position: relative;
}

#footer .fNav .privacy {
	position: absolute;
	bottom: 0;
	right: 0;
}

#footer .fNav .box {
	min-width: 150px;
	display: table-cell;
	vertical-align: top;
}

#footer .fNav .box.short {
	min-width: 100px;
}

#footer .fNav .box:not(:first-child) {
	padding-left: 20px;
}

#footer .fNav .box a {
	text-decoration: none;
}

#footer .fNav .box a:hover {
	text-decoration: underline;
}

#footer .fNav .box dt {
	border-bottom: solid 1px #fff;
	margin-bottom: 15px;
	padding-bottom: 10px;
	font-size: 14px;
	font-size: 1.4rem;
}

#footer .fNav .box li {
	position: relative;
	padding-left: 1em;
	font-size: 12px;
	font-size: 1.2rem;
}

#footer .fNav .box li:before {
	content: "\2D";
	position: absolute;
	top: 0;
	left: 0;
}

#footer .fNav .box li:not(:last-child) {
	margin-bottom: 3px;
}

#footer .bottom {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-top: solid 1px #fff;
	padding-top: 15px;
	font-size: 12px;
	font-size: 1.2rem;
}

#footer .bottom .link:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

#footer .bottom .link li {
	float: left;
	line-height: 1.3;
}

#footer .bottom .link li:not(:last-child) {
	padding-right: 20px;
	position: relative;
}

#footer .bottom .link li:not(:last-child):after {
	content: "";
	background-color: #fff;
	width: 1px;
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;
	margin: auto;
}

#footer .bottom .link a {
	text-decoration: none;
}

#footer .bottom .link a:hover {
	text-decoration: underline;
}

#footer .bottom .copyright {
	text-align: right;
}

/* CSS Document */

.container {
	max-width: 980px;
}

.modSpOnly {
	display: none;
}

.modSpOnly02 {
	display: none;
}

.modInlineBlock {
	display: inline-block;
}

/*
#overview
modTitle
*/

/*
#styleguide
.modTitlePage

```
<header class="modTitlePage">
	<div class="container">
		<h1 class="title">お問い合わせ</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_data">
	<div class="container">
		<h1 class="title">データ送信</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_service">
	<div class="container">
		<h1 class="title"><span class="small">オールインワン議場設備制御システム</span> DiscussBox</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_about">
	<div class="container">
		<h1 class="title">会社案内</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_privacypolicy">
	<div class="container">
		<h1 class="title">個人情報の取り扱いについて</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_policy">
	<div class="container">
		<h1 class="title">利用規約</h1>
	</div>
</header>
```
*/

.modTitlePage {
	background-color: #00376a;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	padding: 80px 0;
	color: #fff;
}

.modTitlePage_recruit {
	background-image: url(../img/recruit/bg_title.jpg);
}

.modTitlePage_data {
	background-image: url(../img/data/bg_title.jpg);
}

.modTitlePage_contact {
	background-image: url(../img/contact/bg_title.jpg);
}

.modTitlePage_service {
	background-image: url(../img/service/bg_title.jpg);
}

.modTitlePage_about {
	background-image: url(../img/about/bg_title.jpg);
}

.modTitlePage_privacypolicy {
	background-image: url(../img/privacypolicy/bg_title.jpg);
}

.modTitlePage_policy {
	background-image: url(../img/policy/bg_title.jpg);
}

.modTitlePage .title {
	font-size: 36px;
	font-size: 3.6rem;
	font-weight: 500;
}

.modTitlePage .small {
	font-size: 28px;
	font-size: 2.8rem;
}

/*
#styleguide
.modTitlePage02

```
<header class="modTitlePage02">
	<div class="container">
		<h1 class="title">お問い合わせ</h1>
	</div>
</header>
```
*/

.modTitlePage02 {
	background-color: #00376a;
	padding: 35px 0;
	text-align: center;
	color: #fff;
}

.modTitlePage02 .title {
	font-size: 36px;
	font-size: 3.6rem;
	font-weight: 500;
}

/*
#styleguide
.modTitle01

```
<h2 class="modTitle01">お問い合わせフォーム</h2>
<h3 class="modTitle01 modTitle01_small">DiscussBoxの主な機能</h3>
```
*/

.modTitle01 {
	position: relative;
	margin-bottom: 50px;
	text-align: center;
	font-weight: bold;
	font-size: 36px;
	font-size: 3.6rem;
}

.modTitle01_small {
	font-size: 28px;
	font-size: 2.8rem;
}

.modTitle01:after {
	content: "";
	width: 100px;
	height: 3px;
	background-color: #58a1d7;
	position: absolute;
	bottom: -20px;
	left: 0;
	right: 0;
	margin: auto;
}

.modTitle01:before {
	content: "";
	position: absolute;
	bottom: -36px;
	right: 0;
	left: 0;
	height: 0;
	width: 0px;
	margin: auto;
	border: 6px solid transparent;
	border-top: 12px solid #58a1d7;
}

/*
#styleguide
.modTitle02

```
<h3 class="modTitle02">文字起こしってどんな仕事？</h3>
<h3 class="modTitle02"><span class="smallTitle">小テキスト</span>文字起こしってどんな仕事？</h3>
<h3 class="modTitle02 modTitle02Color">文字色白</h3>
<h3 class="modTitle02_Small">文字サイズ26px</h3>
<h3 class="modTitle02_Small fontSize18">文字サイズ18px</h3>
```
*/

.modTitle02 {
	margin-bottom: 10px;
	font-size: 30px;
	font-size: 3rem;
	font-weight: bold;
	line-height: 1.3;
}

.modTitle02 .smallTitle {
	font-size: 18px;
	font-size: 1.8rem;
	display: block;
	margin-bottom: 5px;
}

.modTitle02.modTitle02Color {
	color: #fff;
}

.modTitle02_Small {
	margin-bottom: 15px;
	font-size: 26px;
	font-size: 2.6rem;
	font-weight: bold;
	line-height: 1.3;
}

.modTitle02_Small.fontSize18 {
	font-size: 18px;
	font-size: 1.8rem;
}

/*
#styleguide
.modTitleStrong01

```
<p class="modTitleStrong01"><span class="smallTitle">会議録検索システム</span>DiscussNetPremium</p>
```
*/

.modTitleStrong01 {
	margin-bottom: 10px;
	font-size: 30px;
	font-size: 3rem;
	font-weight: bold;
	line-height: 1.3;
	color: #00376a;
}

.modTitleStrong01 .smallTitle {
	font-size: 18px;
	font-size: 1.8rem;
	display: block;
}

h3.modContactBlock01_title {
	text-align: center;
	font-size: 20px;
	font-size: 2rem;
	margin-bottom: 30px;
}

h3.modContactBlock01_title span {
	border-bottom: solid 3px #fff;
	display: inline-block;
}

/*
#overview
modTxt
*/

/*
#styleguide
.modTxtStrong

```
<p class="modTxtStrong">下記条件を全て満たす方</p>
<p class="modTxtStrong04">下記条件を全て満たす方</p>
```
*/

.modTxtStrong {
	font-weight: bold;
	color: #f00;
}

.modTxtStrong04 {
	font-weight: bold;
}

/*
#styleguide
.modTxtStrong02

```
<p class="modTxtStrong02">下記条件を全て満たす方</p>
<p class="modTxtStrong02 modTxtStrong02_smallCenter">下記条件を全て満たす方</p>
<p class="modTxtStrong02 modTxtStrong02_smallLeft">下記条件を全て満たす方</p>
```
*/

.modTxtStrong02 {
	text-align: center;
	font-weight: bold;
	font-size: 26px;
	font-size: 2.6rem;
	line-height: 1.92;
	color: #00376a;
}

.modTxtStrong02.modTxtStrong02_smallCenter {
	font-size: 20px;
	font-size: 2rem;
	text-align: center;
}

.modTxtStrong02.modTxtStrong02_smallLeft {
	font-size: 20px;
	font-size: 2rem;
	text-align: left;
}

/*
#styleguide
.modTxtStrong03

```
<p class="modTxtStrong03">下記条件を全て満たす方</p>
```
*/

.modTxtStrong03 {
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
	font-size: 20px;
	font-size: 2rem;
}

/*
#styleguide
.modTxtStrong

```
<p class="modAlignTxtleft">左寄せ</p>
<p class="modAlignTxtright">右寄せ</p>
<p class="modAlignTxtcenter">中央よせ</p>
```
*/

.modAlignTxtleft {
	text-align: left;
}

.modAlignTxtright {
	text-align: right;
}

.modAlignTxtcenter {
	text-align: center;
}

/*
#overview
modBtn
*/

/*
#styleguide
modBtn01

```
<p><a href="#" class="modBtn01">ボタン</a></p>
<p><a href="#" class="modBtn01 modBtn01_inline">ボタン modBtn01_inline</a></p>
<p><a href="#" class="modBtn01 modBtn01_middle">ボタン modBtn01_middle</a></p>
<p><a href="#" class="modBtn01 modBtn01_large">ボタン modBtn01_large</a></p>
<p><a href="#" class="modBtn01 modBtn01_mail">ボタン modBtn01_mail</a></p>
<p><a href="#" class="modBtn01 modBtn01_mail modBtn01_mail_color01">ボタン modBtn01_mail_color01</a></p>
<p><a href="#" class="modBtn01 modBtn01_color02">ボタン modBtn01_color02</a></p>
<p><a href="#" class="modBtn01 modBtn01_color03">ボタン modBtn01_color03</a></p>
<p><a href="#" class="modBtn01 modBtn01_color04">ボタン modBtn01_color04</a></p>
<p><a href="#" class="modBtn01 modBtn01_anchor">ボタン modBtn01_anchor</a></p>
<p><a href="#" class="modBtn01 modBtn01_arrow">ボタン modBtn01_arrow</a></p>
```
*/

.modBtn01 {
	display: block;
	transition: all 0.2s ease;
	width: 100%;
	padding: 15px 20px;
	border: none;
	background-color: #00376a;
	border-radius: 5px;
	color: #fff;
	text-decoration: none;
	font-weight: bold;
	text-align: center;
	font-size: 18px;
	font-size: 1.8rem;
}

.modBtn01:hover {
	opacity: 0.6;
}

.modBtn01_large {
	padding-top: 35px;
	padding-bottom: 35px;
}

.modBtn01_middle {
	font-size: 16px;
	font-size: 1.6rem;
}

.modBtn01_color02 {
	background-color: #e75e5e;
}

.modBtn01_color03 {
	background-color: #fff;
	color: #00376a;
}

.modBtn01_color04 {
	background-color: #95CAF2;
}

.modBtn01_inline {
	display: inline-block;
	width: auto;
	padding-right: 58px;
	padding-left: 58px;
}

.modBtn01_inline.modBtn01_middle {
	padding-right: 30px;
	padding-left: 30px;
}

.modBtn01_mail:before {
	content: "";
	background-image: url(../img/common/icon_mail.svg);
	background-size: 30px 20px;
	background-position: 0 0;
	background-repeat: no-repeat;
	display: inline-block;
	width: 30px;
	height: 20px;
	margin-right: 16px;
	vertical-align: middle;
}

.modBtn01_mail_color01 {
	background: #fff;
	color: #00376a;
}

.modBtn01_mail_color01:before {
	background-image: url(../img/common/icon_mail2.svg);
	background-size: 30px 20px;
}

.modBtn01_anchor {
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}

.modBtn01_anchor:after {
	content: "";
	display: block;
	width: 16px;
	height: 10px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto 0;
	background-image: url(../img/common/btn_anchor.svg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
}

.modBtn01_arrow {
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}

.modBtn01_arrow:after {
	content: "";
	display: block;
	width: 8px;
	height: 14px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 17px;
	margin: auto 0;
	background-image: url(../img/common/btn_arrow.svg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
}

.modBtn01.uploadnow {
	background: #ccc;
}

.modBtn01.uploadnow:hover {
	opacity: 1;
}

/*
#styleguide
modBtnBox

```
<p class="modBtnBox"><button class="modBtn01 modBtn01_inline">ログイン</button></p>
```
*/

.modBtnBox {
	text-align: center;
}

* + .modBtnBox {
	margin-top: 40px;
}

/*
#styleguide
modBtnList

```

<ul class="modBtnList">
	<li><button class="modBtn01 modBtn01_middle">確認画面へ</button></li>
	<li><button class="modBtn01 modBtn01_color02 modBtn01_middle">リセット</button></li>
</ul>

```
*/

.modBtnList {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 380px;
	margin-right: auto;
	margin-left: auto;
}

* + .modBtnList {
	margin-top: 70px;
}

.modBtnList li {
	width: 48%;
}

/*
#styleguide
modBtnList02

```

<ul class="modBtnList02">
	<li><a href=""><span class="lilstIcon bgKeyword">キーワードで探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgConference">会議の種類から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgRemark">発言者から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgSearch">検索履歴から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgAssociation">連想語・類似文から探す</span></a></li>
	<li><a href=""><span class="lilstIcon bgCreate">発言集作成</span></a></li>
</ul>

```
*/

.modBtnList02 {
	clear: both;
	overflow: hidden;
}

.modBtnList02 li {
	width: 32%;
	margin-right: 2%;
	float: left;
	margin-bottom: 2%;
	display: block;
	background: #daefff;
	color: #00376a;
	text-decoration: none;
	padding: 0 25px;
	font-weight: bold;
}

.modBtnList02 li:nth-child(3n) {
	float: right;
	margin-right: 0;
}

.modBtnList02 li:nth-child(3n+1) {
	clera: both;
}

.modBtnList02 li .lilstIcon {
	padding: 25px 0 25px 60px;
	display: block;
}

.modBtnList02 li .lilstIcon.bgKeyword {
	background: url(../img/common/btn_keyword.png) left center no-repeat;
	background-size: 30px auto;
}

.modBtnList02 li .lilstIcon.bgConference {
	background: url(../img/common/btn_conference.png) left center no-repeat;
	background-size: 37px auto;
}

.modBtnList02 li .lilstIcon.bgRemark {
	background: url(../img/common/btn_remark.png) left center no-repeat;
	background-size: 35px auto;
}

.modBtnList02 li .lilstIcon.bgSearch {
	background: url(../img/common/btn_search.png) left center no-repeat;
	background-size: 30px auto;
}

.modBtnList02 li .lilstIcon.bgAssociation {
	background: url(../img/common/btn_association.png) left center no-repeat;
	background-size: 36px auto;
}

.modBtnList02 li .lilstIcon.bgCreate {
	background: url(../img/common/btn_create.png) left center no-repeat;
	background-size: 34px auto;
}

/*
#overview
modBlock
*/

/*
#styleguide

```
<div class="modBlockInner01">上下にpaddingがつく</div>
```
*/

.modBlockInner01 {
	padding: 50px 0 70px;
}

/*
#styleguide
.modBlockTrim
```
<div class="modBlockInner01">
	<div class="modBlockTrim">modBlockInner01の下余白を内側から消す</div>
</div>
```
*/

.modBlockTrim {
	margin-bottom: -70px;
}

.modBlockLoginBody {
	padding: 40px 60px 50px;
	margin: 0 auto;
	max-width: 524px;
	background-color: #daefff;
}

* + .modBlockLoginBody {
	margin-top: 60px;
}

.modBlockLoginBody .form dt {
	margin-bottom: 5px;
	font-weight: bold;
}

.modBlockLoginBody .form dd + dt {
	margin-top: 20px;
}

.modBlockLoginBody .saveCheck {
	margin-top: 15px;
}

.modBlockLoginBody .policy {
	border-top: solid 1px #fff;
	padding-top: 40px;
	margin-top: 40px;
}

.modBlockLoginBody .policy dt {
	margin-bottom: 10px;
	font-weight: 500;
}

.modBlockLoginBody .policy dt .required {
	display: inline-block;
	background-color: #e75e5e;
	padding: 3px 15px 5px;
	margin-right: 10px;
	line-height: 1.5;
	color: #fff;
}

.modBlockLoginReminder {
	margin-top: 100px;
	text-align: center;
}

/*
#styleguide
.modMargin
```
<div class="modMargin">下方向の共通余白</div>
```
*/

.modMargin {
	margin-bottom: 80px;
}

/*
#styleguide
.modImgBlock
```
<div class="modImgBlock">
  <div class="modImgBlock_txt">
    <h3 class="modTitle02">文字起こしってどんな仕事？</h3>
    <div class="modImgBlock_comment">
      <p>録音された音声を足踏みの再生機を使用して聞きながら、パソコンで文字（日本語）に起こしていただく仕事です。<br>
      内容は、地方議会、各種会議、講演会、インタビュー等、多岐にわたります。<br>
      今現在の社会情勢や情報を生の声で聞くことができます。</p>
    </div>
  </div>
  <div class="modImgBlock_image"><img src="../assets/img/recruit/recruit_img1.jpg" alt=""></div>
</div>
```
*/

.modImgBlock:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.modImgBlock_txt {
	float: left;
	width: 44.5%;
}

.modImgBlock_image {
	float: right;
	width: 51.064%;
	text-align: center;
}

/*
#styleguide
.modBgBlock
```
<div class="modBgBlock"></div>
<div class="modBgBlock colorType02"></div>
```
*/

.modBgBlock {
	position: relative;
	padding: 70px 0 80px;
	background: #e5f4ff;
	z-index: 1;
}

.modBgBlock:before {
	content: "";
	position: absolute;
	top: 0;
	left: -10000px;
	right: -10000px;
	width: 10000px;
	height: 100%;
	margin: 0 auto;
	background: #e5f4ff;
	z-index: -1;
}

.modBgBlock_thin {
	position: relative;
	padding: 70px 0 80px;
	background: #f4faff;
	z-index: 1;
}

.modBgBlock_thin:before {
	content: "";
	position: absolute;
	top: 0;
	left: -10000px;
	right: -10000px;
	width: 10000px;
	height: 100%;
	margin: 0 auto;
	background: #f4faff;
	z-index: -1;
}

/*
#styleguide
.modContactBlock01
```

<div class="modContactBlock01">
	<div class="modContactBlock01In">
		<div class="detail">
			<p class="siteTitle">株式会社議事録発行センター</p>
			<p class="tel"><span class="free modTelLink">0120-62-1332</span><span class="pause">/</span><a href="tel:0862551332">086-255-1332</a></p>
			<p>平日 8:30～17:20 (土日祝日は除く)</p>
		</div>
		<div class="btn">
			<a href="#" class="modBtn01 modBtn01_large modBtn01_mail modBtn01_mail_color01">お問い合わせフォームへ</a>
		</div>
	</div>
</div>
```
*/

.modContactBlock01 {
	padding: 30px 0 50px 0;
	position: relative;
	color: #fff;
	margin-top: 100px;
}

.modContactBlock01:before {
	content: "";
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background: #00376a;
	z-index: -1;
}

.modContactBlock01 .modContactBlock01In {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 50px;
}

.modContactBlock01 .modContactBlock01In .detail {
	width: 60%;
}

.modContactBlock01 .modContactBlock01In .detail .siteTitle {
	font-size: 20px;
	font-size: 2rem;
	font-weight: bold;
}

.modContactBlock01 .modContactBlock01In .detail .tel {
	font-size: 24px;
	font-size: 2.4rem;
	font-weight: bold;
	line-height: 1.5;
}

.modContactBlock01 .modContactBlock01In .detail .tel .free {
	font-size: 36px;
	font-size: 3.6rem;
}

.modContactBlock01 .modContactBlock01In .detail .tel .pause {
	margin: 0 10px;
}

.modContactBlock01 .modContactBlock01In .detail .tel a {
	text-decoration: none;
	color: #fff;
}

.modContactBlock01 .modContactBlock01In .btn {
	width: 40%;
	padding-left: 15px;
}

/*
#styleguide
.modBgBlock02
```
<div class="modBgBlock02">背景#eeeeee</div>
```
*/

.modBgBlock02 {
	background: #eeeeee;
	padding: 30px;
}

/*
#overview
modTable
*/

/*
#styleguide
.modTable

```
<table class="modTable">
	<tr class="modTable_r">
		<th class="modTable_h">仕事内容</th>
		<td class="modTable_d">録音された音声の文字起こし</td>
	</tr>
</table>
```
*/

.modTable {
	width: 100%;
	border-collapse: collapse;
}

.modTable_h,
.modTable_d {
	padding: 20px 0;
	vertical-align: top;
}

.modTable_h {
	width: 200px;
	font-weight: bold;
	text-align: left;
	border-bottom: 1px solid #6eaedc;
}

.modTable_d {
	padding-left: 10px;
	border-bottom: 1px solid #e2e2e2;
}

.modTable2 th {
	width: 20%;
}

/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
<ul class="modList01">
    <li>Illustrator に原寸で配置したサイズでの解像度が350dpiになるようにしてください。</li>
    <li> 拡大縮小率70%〜141％を超えると画質が劣化するのでご注意ください。</li>
</ul>
<ul class="modList02">
    <li>リストリストリストリストリストリストリストリスト<br>リストリストリストリストリストリスト</li>
    <li>リスト</li>
</ul>
<ul class="modList02 listFloat">
    <li>リスト<br>リストリストリストリストリストリスト</span></li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
    <li>リスト</li>
</ul>
```
*/

.modListNormal {
	margin: 0 0 0 2em;
}

ul.modListNormal li:before {
	content: "\0030fb";
	margin-left: -2em;
	display: inline-block;
	width: 2em;
	text-align: center;
}

ol.modListNormal li {
	list-style: decimal;
}

ul.modList02 li {
	padding-left: 17px;
	position: relative;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.625;
	margin-bottom: 10px;
}

ul.modList02 li:before {
	display: block;
	content: "";
	height: 8px;
	width: 8px;
	background: #00376a;
	position: absolute;
	top: 10px;
	left: 0;
}

ul.modList02.listFloat {
	overflow: hidden;
}

ul.modList02.listFloat li {
	float: left;
	width: 23%;
	margin-right: 2%;
}

ul.modList02.listFloat li:nth-child(4n) {
	float: right;
	margin-right: 0;
}

ul.modList02.listFloat li:nth-child(4n+1) {
	clear: both;
}

/* indent */

.modIndent01.mod_ml30 {
	margin-left: 30px;
}

.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

/* フォーム
***************************************/

.modDlForm {
	margin: 0 0 20px;
}

.modDlForm dt {
	float: left;
	clear: both;
	width: 16em;
	padding: 33px 0 20px 63px;
	position: relative;
}

.modDlForm dt.required:before,
.modDlForm dt.any:before {
	position: absolute;
	left: 0;
	top: 33px;
	color: #FFF;
	font-size: 11px;
	font-size: 1.1rem;
	padding: 2px 10px;
}

.modDlForm dt.required:before {
	content: "必須";
	background: #E33E41;
}

.modDlForm dt.any:before {
	background: #999999;
	content: "任意";
}

.modDlForm dd {
	border-bottom: solid 1px #ccc;
	padding: 10px 0;
	padding-left: 17em;
}

.modDlForm dd .parts {
	padding: 10px 0;
}

.modDlForm dd .parts textarea,
.modDlForm dd .parts input[type="text"],
.modDlForm dd .parts input[type="email"] {
	width: 100%;
}

.modDlForm dd .parts.radio_inline > div,
.modDlForm dd .parts.check_inline > div {
	display: inline-block;
}

.modDlForm dd .parts.name {
	display: flex;
	justify-content: space-between;
}

.modDlForm dd .parts.name > div {
	width: 48.5%;
}

.modDlForm dd .parts.post,
.modDlForm dd .parts.tel {
	display: flex;
}

.modDlForm dd .parts.post .hyphen,
.modDlForm dd .parts.tel .hyphen {
	padding: 10px 10px 0 10px;
}

.modDlForm dd .parts.password input {
	width: 100%;
	max-width: 300px;
}

.modDlForm dd .parts.password .text {
	padding: 10px 0;
}

.modDlForm .validationError {
	color: #E33E41;
}

/*
#styleguide
modColumn

```
<ul class="modColumn modColumnPc_3n modColumnTb_3n modColumnSp_3n">
    <li>3nリスト</li>
    <li>3nリスト</li>
    <li>3nリスト</li>
</ul>
<ul class="modColumn modColumnPc_2n modColumnTb_2n modColumnSp_2n">
    <li>2nリスト</li>
    <li>2nリスト</li>
</ul>
```
*/

.modColumn:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.modColumn > li {
	float: left;
}

/*
#styleguide
modListBg

```
<div class="modListBg">
  <ol class="modListBg_list">
    <li><span>国語力のある方。基本的な語彙力、文章力をお持ちの方</span></li>
    <li><span>細かい作業が得意な方</span></li>
    <li><span>根気・やる気があり、仕事に対して最後まで責任を持てる方</span></li>
    <li><span>時間管理・自己管理ができる方</span></li>
    <li><span>時事問題や社会情勢等に興味関心を抱き、向上心・探究心のある方</span></li>
    <li><span>守秘義務(知り得た個人情報を第三者に漏えいしない)を遵守できる方</span></li>
  </ol>
</div>
```
*/

.modListBg {
	padding: 40px;
	background: #e5f4ff;
}

.modListBg_list {
	display: table;
	counter-reset: bgList;
}

.modListBg_list > li {
	display: table-row;
	counter-increment: bgList;
}

.modListBg_list > li:before,
.modListBg_list > li > div,
.modListBg_list > li > span {
	display: table-cell;
}

.modListBg_list > li:before {
	content: counter(bgList) ".";
	font-weight: bold;
	white-space: nowrap;
	padding-right: 10px;
}

.modListBg_list > li:nth-child(n+2):before,
.modListBg_list > li:nth-child(n+2) > span {
	padding-top: 5px;
}

/*
#styleguide
modListStep

```
<ol class="modListStep">
  <li>
    <div class="modListStep_num">Step<span>1</span></div>
    <div class="modListStep_content">
      <p class="modListStep_title">エントリー</p>
      <div class="modListStep_txt">
        <p class="modListStep_phrase">応募フォームに必要事項を入力の上、送信してください。<br>
      選考後、合格の方には技能試験のご案内をメール送信いたします。</p>
      </div>
    </div>
  </li>
  <li>
    <div class="modListStep_num">Step<span>2</span></div>
    <div class="modListStep_content">
      <p class="modListStep_title">技能試験</p>
      <div class="modListStep_txt">
        <p class="modListStep_phrase">お手持ちのパソコンを使用して、ご自宅で技能試験を受けていただきます。<br>
      合格の方には、仕事内容についてメール送信いたします。</p>
      </div>
    </div>
  </li>
</ol>
```
*/

.modListStep > li {
	position: relative;
	padding: 40px;
	background: #fff;
}

.modListStep > li:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.modListStep > li:nth-child(n+2) {
	margin-top: 30px;
}

.modListStep > li:nth-child(n+2):before {
	position: absolute;
	top: -24px;
	left: 0;
	right: 0;
	content: "";
	width: 200px;
	height: 30px;
	margin: 0 auto;
	background: url(../img/common/arrow1.png) no-repeat left top;
	background-size: 100%;
}

.modListStep > li .modListStep_phrase + .modListStep_phrase {
	margin-top: 25px;
}

.modListStep_num {
	float: left;
	margin-right: 35px;
	width: 110px;
	min-height: 110px;
	padding-top: 24px;
	font-size: 30px;
	font-size: 3rem;
	font-weight: bold;
	color: #fff;
	line-height: 1.082;
	background: #00376a;
	text-align: center;
	border-radius: 110px;
}

.modListStep_num > span {
	display: block;
}

.modListStep_content {
	overflow: hidden;
}

.modListStep_title {
	margin-bottom: 15px;
	font-size: 30px;
	font-size: 3rem;
	font-weight: bold;
	line-height: 1.2;
	color: #00376a;
}

/*
#styleguide
modIconList
```
<ul class="modIconList">
  <li>
    <div class="modIconList_icon"><img src="../assets/img/common/icon_camera.png" alt=""></div>
    <div class="modIconList_txt">
      <p class="modIconList_title j_match">マニュアルなしでも簡単・迅速にカメラ・マイクなどの議場設備一括操作</p>
      <div class="modIconList_comment">
        <p>カメラ・マイクの一括操作から議会映像配信まで、マニュアルなしでも直感的に操作が可能です。</p>
      </div>
    </div>
  </li>
  <li>
    <div class="modIconList_icon"><img src="../assets/img/common/icon_earth.png" alt=""></div>
    <div class="modIconList_txt">
      <p class="modIconList_title j_match">議会中継システムと連動で簡単インターネット配信</p>
      <div class="modIconList_comment">
        <p>高性能エンコーダー内蔵で、議会中継システムDiscussVisionSmartと連動しインターネット配信を簡単に実現。庁内向け同時配信も可能。放送用SDIボード採用で、複数のカメラでも最高品質の映像が提供できます。</p>
      </div>
    </div>
  </li>
  <li>
    <div class="modIconList_icon"><img src="../assets/img/common/icon_price.png" alt=""></div>
    <div class="modIconList_txt">
      <p class="modIconList_title j_match">機器を集約導入コスト、<br class="modPcOnly">ランニングコストを低減</p>
      <div class="modIconList_comment">
        <p>エンコーダー、カメラコントローラーなどの機器を内蔵しているため、導入時のイニシャルコストだけではなく、ランニングコスト低減により、導入後の継続的なコストダウンが可能!</p>
      </div>
    </div>
  </li>
</ul>
```
*/

.modIconList {
	display: flex;
	justify-content: space-between;
}

.modIconList > li {
	overflow: hidden;
	width: 31.17%;
	padding: 30px 20px;
	background: #fff;
}

.modIconList_icon {
	margin-bottom: 20px;
	text-align: center;
}

.modIconList_icon img {
	width: 110px;
}

.modIconList_title {
	margin-bottom: 10px;
	font-size: 20px;
	font-size: 2rem;
	font-weight: bold;
	line-height: 1.6;
	text-align: center;
	color: #00376a;
}

.modIconList_txt {
	overflow: hidden;
}

/*
#styleguide
modDetailsList
```
<ul class="modDetailsList">
	<li>
		<p class="listTitle">超高速全文検索エンジンを搭載して、スピードアップ!</p>
		<div class="listText">
			<ul class="list01">
				<li>・高速全文検索エンジン(EFS)を搭載、膨大な会議録データでも超高速に検索</li>
				<li>・思いついた言葉を入力するだけですぐさま検索を実行</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">議会用語を含む約17万語のシソーラス辞書を搭載</p>
		<div class="listText">
			<ul class="list01">
				<li>・17万語から成るシソーラス辞書(関連語辞書)を標準搭載</li>
				<li>・会議録には絶対不可欠となる口語体文書に威力を発揮</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">連想語・類似文検索機能</p>
		<div class="listText">
			<p class="text01">NTT研究所開発の日本語検索処理技術により、効果的な利用が可能</p>
			<ul class="list01">
				<li>・話題語抽出 - 話題性の高いワードランキングの表示</li>
				<li>・連想語検索 - 曖昧なキーワード検索でも連想語を表示</li>
				<li>・類似文検索 - 過去の会議録から類似事例を抽出</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">外部リンクとの対応</p>
		<div class="listText">
			<ul class="list01">
				<li>・例規情報システム「REIKI-BASE」との連携</li>
				<li>・政務調査管理システム「Power Finder」との連携によりスピーディーに情報、資料を収集可能</li>
				<li>・外部リンクの設定も可能になり関連ドキュメントを素早く入手</li>
			</ul>
		</div>
	</li>
</ul>

<ul class="modDetailsList listColor02">
	<li>
		<p class="listTitle">超高速全文検索エンジンを搭載して、スピードアップ!</p>
		<div class="listText">
			<ul class="list01">
				<li>・高速全文検索エンジン(EFS)を搭載、膨大な会議録データでも超高速に検索</li>
				<li>・思いついた言葉を入力するだけですぐさま検索を実行</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">議会用語を含む約17万語のシソーラス辞書を搭載</p>
		<div class="listText">
			<ul class="list01">
				<li>・17万語から成るシソーラス辞書(関連語辞書)を標準搭載</li>
				<li>・会議録には絶対不可欠となる口語体文書に威力を発揮</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">連想語・類似文検索機能</p>
		<div class="listText">
			<p class="text01">NTT研究所開発の日本語検索処理技術により、効果的な利用が可能</p>
			<ul class="list01">
				<li>・話題語抽出 - 話題性の高いワードランキングの表示</li>
				<li>・連想語検索 - 曖昧なキーワード検索でも連想語を表示</li>
				<li>・類似文検索 - 過去の会議録から類似事例を抽出</li>
			</ul>
		</div>
	</li>
	<li>
		<p class="listTitle">外部リンクとの対応</p>
		<div class="listText">
			<ul class="list01">
				<li>・例規情報システム「REIKI-BASE」との連携</li>
				<li>・政務調査管理システム「Power Finder」との連携によりスピーディーに情報、資料を収集可能</li>
				<li>・外部リンクの設定も可能になり関連ドキュメントを素早く入手</li>
			</ul>
		</div>
	</li>
</ul>
```
*/

.modDetailsList {
	overflow: hidden;
}

.modDetailsList > li {
	width: 49%;
	margin-bottom: 25px;
}

.modDetailsList > li:nth-child(odd) {
	float: left;
	clear: both;
}

.modDetailsList > li:nth-child(even) {
	float: right;
}

.modDetailsList > li .listTitle {
	font-weight: bold;
	font-size: 18px;
	font-size: 1.8rem;
	text-align: center;
	background: #daefff;
	padding: 10px;
	margin-bottom: 10px;
}

.modDetailsList > li .list01 li {
	margin-bottom: 5px;
	text-indent: -1em;
	padding-left: 1em;
}

.modDetailsList > li .text01 {
	margin-bottom: 10px;
}

.modDetailsList.listColor02 .listTitle {
	background: #fff;
}

.modSitemapList {
	line-height: 2rem;
	width: 80%;
	margin: 0 auto;
	font-size: 16px;
	font-size: 1.6rem;
}

.modSitemapList li p {
	border-left: solid 5px #00376a;
	margin-bottom: 10px;
}

.modSitemapList li p a {
	padding-left: 15px;
	font-size: 20px;
	font-size: 2rem;
}

.modSitemapList ol {
	margin-left: 20px;
	margin-bottom: 40px;
}

.modSitemapList ol li {
	border-bottom: dashed 1px #ccc;
}

.modSitemapList a {
	text-decoration: none;
	display: block;
	padding: 10px 5px;
}

.modSitemapList a:hover {
	background: #efefef;
}

/*
#overview
modForm
*/

/*
#styleguide
xxxxx

```
コード
```
*/

.form_attention {
	font-size: 14px;
	font-size: 1.4rem;
	color: #666;
}

.modFormLead {
	margin-bottom: 50px;
}

.modFormLead.modFormLead_red {
	color: #ff0000;
}

.modFormLead.modFormLead_red strong {
	font-weight: bold;
}

.modFormList {
	background-color: #daefff;
	padding: 50px 40px;
}

.modFormList + .modFormList {
	margin-top: 50px;
}

.modFormList .title {
	margin-bottom: 30px;
	font-weight: bold;
	font-size: 22px;
	font-size: 2.2rem;
	text-align: center;
}

.modFormList > .item {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.modFormList > .item:not(:last-child) {
	border-bottom: solid 1px #fff;
	padding-bottom: 30px;
	margin-bottom: 30px;
}

.modFormList > .item.vCenter {
	align-items: center;
}

.modFormList > .item > dt {
	width: 270px;
	padding-right: 75px;
	position: relative;
	font-weight: bold;
}

.modFormList > .item > dt.noRequired {
	padding-right: 0;
}

.modFormList > .item > dt .required {
	background-color: #e75e5e;
	position: absolute;
	top: 0;
	right: 12px;
	display: inline-block;
	width: 60px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	color: #fff;
	font-weight: 500;
}

.modFormList > .item > dt .sub {
	display: block;
	font-size: 14px;
	font-size: 1.4rem;
}

.modFormList > .item > dt .br {
	display: block;
}

.modFormList > .item.vCenter > dt .required {
	bottom: 0;
	margin: auto;
}

.modFormList > .item > dd {
	flex: 1;
}

.modFormCalendar {
	position: relative;
	max-width: 330px;
	padding-right: 60px;
}

.modFormCalendar input {
	width: 280px;
	max-width: 100%;
}

.modFormCalendar .btnCalendar {
	display: block;
	background-image: url(../img/common/icon_calendar.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 28px 31px;
	width: 60px;
	height: 40px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.modFormZip {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.modFormZip .input {
	width: 134px;
}

.modFormZip .btn {
	padding-left: 20px;
	width: 160px;
}

.modFormZip .btn .modBtn01 {
	padding-top: 4px;
	padding-bottom: 6px;
	font-size: 16px;
	font-size: 1.6rem;
}

.modFormCheckList li:not(:last-child) {
	margin-bottom: 5px;
}

.modFormCheckList .checkInput {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.modFormCheckList .checkInput .check {
	width: 6em;
}

.modFormCheckList .checkInput .input {
	flex: 1;
}

.modFormCheckList .checkInput .input input {
	padding-top: 5px;
	padding-bottom: 5px;
}

.modFormRadioList:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.modFormRadioList li {
	float: left;
}

.modFormRadioList li:not(:last-child) {
	margin-left: 10px;
}

.modFormTime {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.modFormTime .input {
	width: 100px;
}

.modFormTime .txt {
	margin-left: 15px;
}

.modFormError {
	margin-top: 8px;
	color: #e75e5e;
}

.modFormBirthday {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.modFormBirthday .year {
	width: 110px;
}

.modFormBirthday .month,
.modFormBirthday .date {
	width: 90px;
}

.modFormBirthday .txt {
	width: 1.5em;
	padding-left: 9px;
}

.modFormBirthday .txt + .input {
	margin-left: 20px;
}

.modFormConfBlock {
	border-radius: 25px;
	border: solid 10px #dcdcdc;
	padding: 30px;
	margin: 80px 0;
}

.modFormConfBlock .wrapper {
	padding-right: 30px;
	padding-left: 30px;
}

.modFormConfBlock .wrapper + .wrapper {
	border-top: solid 1px #cfcfcf;
	padding-top: 30px;
	margin-top: 40px;
}

.modFormConfBlock .wrapper .confTitle {
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 20px;
	font-size: 2rem;
}

.modFormConfBlock .wrapper .wrapperInner {
	margin-left: 1.2em;
}

.modFormConfBlock table th {
	width: 6em;
	font-weight: bold;
}

.modFormConfBlock table td {
	color: #666666;
}

.modFormConfBlock table th,
.modFormConfBlock table td {
	padding-bottom: 15px;
	vertical-align: top;
}

.modFormConfBlock .table2 th {
	width: 30%;
	padding-right: 20px;
}

.modFormZip input[type='number'],
.modFormTime input[type='number'] {
	-moz-appearance: textfield;
}

.modFormZip input[type=number]::-webkit-inner-spin-button,
.modFormZip input[type=number]::-webkit-outer-spin-button,
.modFormTime input[type=number]::-webkit-inner-spin-button,
.modFormTime input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/*
#overview
other
*/

/*
#styleguide
.resultsLabel

```
<p class="resultsLabel colorDnp">DNP</p>
<p class="resultsLabel colorDvl">DVL</p>
<p class="resultsLabel colorDvs">DVS</p>
<p class="resultsLabel colorDc">DC</p>
<p class="resultsLabel colorDw">DW</p>
```
*/

.resultsLabel {
	width: 70px;
	text-align: center;
	border-radius: 5px;
	color: #fff;
	font-size: 18px;
	font-size: 1.8rem;
	padding: 5px;
	line-height: 1.2;
	font-weight: bold;
}

.resultsLabel.colorDnp {
	background: #213fa0;
}

.resultsLabel.colorDvl {
	background: #632f97;
}

.resultsLabel.colorDvs {
	background: #2c6436;
}

.resultsLabel.colorDc {
	background: #786d2e;
}

.resultsLabel.colorDw {
	background: #803838;
}

@media only screen and (max-width: 992px) and (min-width: 601px) {

.modColumnTb_1n > li {
	float: none;
}

.modColumnTb_2n > li {
	float: right;
	width: 48.936%;
}

.modColumnTb_2n > li:nth-child(2n+1) {
	clear: both;
	float: left;
}

.modColumnTb_3n > li {
	width: 31.17%;
	margin-left: 3.245%;
}

.modColumnTb_3n > li:nth-child(3n+1) {
	clear: both;
	margin-left: 0;
}

}

@media only screen and (min-width: 993px) {

.modColumnPc_1n > li {
	float: none;
}

.modColumnPc_2n > li {
	float: right;
	width: 48.936%;
}

.modColumnPc_2n > li:nth-child(2n+1) {
	clear: both;
	float: left;
}

.modColumnPc_3n > li {
	width: 31.17%;
	margin-left: 3.245%;
}

.modColumnPc_3n > li:nth-child(3n+1) {
	clear: both;
	margin-left: 0;
}

}

@media only screen and (max-width: 992px) {

#header {
	height: 60px;
}

#header .container {
	display: block;
}

#header .siteTitle {
	width: 200px;
	margin-top: 3px;
}

#header .gNavBtnMenu {
	display: block;
}

#gNav {
	position: absolute;
	top: 0;
	right: -50vw;
	width: 50vw;
	height: 100%;
	transition: all 0.2s ease;
}

body.gnavopen #gNav {
	right: 0;
}

#gNav .btnClose {
	display: block;
}

#gNav .overlay {
	display: block;
}

body.gnavopen #gNav .overlay {
	right: 0;
}

#gNav .inner {
	position: relative;
	padding: 60px 20px 0;
}

#gNav .nav {
	display: block;
}

#gNav .item:not(:first-child) {
	margin-left: 0;
}

#gNav .item {
	border-bottom: solid 1px #fff;
}

#gNav .item:first-child {
	border-top: solid 1px #fff;
}

#gNav .item a {
	text-align: left;
	color: #fff;
	font-size: 15px;
	font-size: 1.5rem;
}

#gNav .item a:hover {
	color: #fff;
}

#service #gNav .item.service a {
	color: #fff;
}

#staff #gNav .item.staff a {
	color: #fff;
}

#company #gNav .item.company a {
	color: #fff;
}

#contact #gNav .item.contact a {
	color: #fff;
}

#data #gNav .item.data a {
	color: #fff;
}

.topicpath {
	font-size: 12px;
	font-size: 1.2rem;
}

#footer .contact .container {
	display: block;
}

#footer .contact .detail {
	width: auto;
	margin-bottom: 20px;
}

#footer .contact .btn {
	width: auto;
	padding-left: 0;
}

#footer .fNav {
	display: block;
	width: auto;
}

#footer .fNav .privacy {
	text-align: center;
	position: static;
}

#footer .fNav .box {
	display: block;
	min-width: 0;
}

#footer .fNav .box.short {
	min-width: 0;
}

#footer .fNav .box:not(:first-child) {
	padding-left: 0;
}

#footer .fNav .box:not(:last-child) {
	margin-bottom: 40px;
}

#footer .bottom {
	display: block;
}

#footer .bottom .link {
	margin-bottom: 20px;
	text-align: center;
}

#footer .bottom .link li {
	float: none;
	display: inline-block;
}

#footer .bottom .copyright {
	text-align: center;
}

.container {
	max-width: 750px;
}

.modPcOnly {
	display: none;
}

.modSpOnly {
	display: block;
}

.modBtnList02 li:nth-child(3n) {
	float: left;
}

.modBtnList02 li:nth-child(3n+1) {
	clera: none;
}

.modBtnList02 li {
	width: 48%;
	margin-right: 0;
}

.modBtnList02 li:nth-child(odd) {
	clera: both;
}

.modBtnList02 li:nth-child(even) {
	float: right;
}

.modBtnList02 li .lilstIcon {
	padding: 20px 0 20px 45px;
}

.modBtnList02 li {
	padding: 0 10px;
}

.modMargin {
	margin-bottom: 40px;
}

.modImgBlock_txt {
	margin-bottom: 20px;
}

.modImgBlock_txt,
.modImgBlock_image {
	float: none;
	width: auto;
}

.modBgBlock {
	padding: 40px 0;
}

.modContactBlock01 .modContactBlock01In {
	display: block;
	padding: 30px;
}

.modContactBlock01 .modContactBlock01In .detail {
	width: auto;
	margin-bottom: 20px;
}

.modContactBlock01 .modContactBlock01In .btn {
	width: auto;
	padding-left: 0;
}

.modTable,
.modTable > tbody,
.modTable_r,
.modTable_h,
.modTable_d {
	display: block;
	width: auto;
}

.modTable_h {
	padding-top: 0;
	padding-bottom: 10px;
}

.modTable_d {
	padding-top: 10px;
	padding-left: 0;
	border-bottom: 0;
}

ul.modList02.listFloat li {
	width: 48%;
	margin-right: 0;
}

ul.modList02.listFloat li:nth-child(4n) {
	float: left;
}

ul.modList02.listFloat li:nth-child(4n+1) {
	clear: none;
}

ul.modList02.listFloat li:nth-child(odd) {
	clear: both;
}

ul.modList02.listFloat li:nth-child(even) {
	float: right;
}

.modDlForm dt {
	float: none;
	width: auto;
	padding: 33px 0 0 63px;
}

.modDlForm dd {
	padding: 10px 0;
}

.modListStep > li {
	padding: 20px;
}

.modIconList {
	display: block;
}

.modIconList > li {
	width: auto;
}

.modIconList > li:nth-child(n+2) {
	margin-top: 10px;
}

.modIconList_icon {
	float: left;
	margin: 0 20px 0 0;
}

.modIconList_title {
	text-align: left;
}

.modDetailsList > li {
	width: auto;
}

.modDetailsList > li:nth-child(odd) {
	float: none;
}

.modDetailsList > li:nth-child(even) {
	float: none;
}

.modSitemapList {
	width: auto;
}

.modFormList > .item > dt {
	width: 30%;
}

.modFormList > .item > dt .br {
	display: inline;
}

}

@media only screen and (max-width: 600px) {

body {
	font-size: 14px;
	font-size: 1.4rem;
}

textarea,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="email"],
select {
	padding: 6px 10px;
}

select {
	height: auto;
	line-height: 1.5;
}

#footer .contact {
	padding: 50px 0;
}

#footer .contact .detail .siteTitle {
	font-size: 18px;
	font-size: 1.8rem;
}

#footer .contact .detail .tel {
	font-size: 18px;
	font-size: 1.8rem;
}

#footer .contact .detail .tel .free {
	font-size: 26px;
	font-size: 2.6rem;
}

#footer .contact .detail .tel .pause {
	margin: 0 5px;
}

#footer .pageTop {
	width: 50px;
}

#footer .bottom .link {
	font-size: 11px;
	font-size: 1.1rem;
}

.modPcOnly02 {
	display: none;
}

.modSpOnly02 {
	display: block;
}

.modTitlePage {
	padding: 6vh 0;
}

.modTitlePage .title {
	font-size: 22px;
	font-size: 2.2rem;
}

.modTitlePage .small {
	font-size: 18px;
	font-size: 1.8rem;
}

.modTitlePage02 {
	padding: 6vh 0;
}

.modTitlePage02 .title {
	font-size: 22px;
	font-size: 2.2rem;
}

.modTitle01 {
	font-size: 22px;
	font-size: 2.2rem;
}

.modTitle01_small {
	font-size: 20px;
	font-size: 2rem;
}

.modTitle02 {
	font-size: 18px;
	font-size: 1.8rem;
}

.modTitle02 .smallTitle {
	font-size: 15px;
	font-size: 1.5rem;
}

.modTitle02_Small {
	font-size: 20px;
	font-size: 2rem;
}

.modTitleStrong01 {
	font-size: 18px;
	font-size: 1.8rem;
}

.modTitleStrong01 .smallTitle {
	font-size: 15px;
	font-size: 1.5rem;
}

h3.modContactBlock01_title {
	margin-bottom: 0px;
}

.modTxtStrong02 {
	font-size: 18px;
	font-size: 1.8rem;
	text-align: left;
}

.modTxtStrong02.modTxtStrong02_smallCenter {
	font-size: 18px;
	font-size: 1.8rem;
}

.modTxtStrong02.modTxtStrong02_smallLeft {
	font-size: 18px;
	font-size: 1.8rem;
}

.modTxtStrong03 {
	font-size: 18px;
	font-size: 1.8rem;
}

.modBtn01 {
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 17px;
	font-size: 1.7rem;
}

.modBtn01_large {
	padding-top: 20px;
	padding-bottom: 20px;
}

.modBtn01_middle {
	font-size: 14px;
	font-size: 1.4rem;
}

* + .modBtnList {
	margin-top: 40px;
}

.modBtnList02 li:nth-child(3n) {
	float: none;
}

.modBtnList02 li {
	width: auto;
	float: none;
}

.modBtnList02 li:nth-child(odd) {
	clera: none;
}

.modBtnList02 li:nth-child(even) {
	float: none;
}

.modBlockInner01 {
	padding-top: 30px;
	padding-bottom: 40px;
}

.modBlockTrim {
	margin-bottom: -40px;
}

.modBlockLoginBody {
	padding: 20px;
}

* + .modBlockLoginBody {
	margin-top: 40px;
}

.modBlockLoginBody .policy {
	padding-top: 20px;
	margin-top: 20px;
}

.modBlockLoginBody .policy dt .required {
	padding: 2px 6px 3px;
}

.modBlockLoginReminder {
	margin-top: 50px;
}

.modContactBlock01 {
	padding: 20px 0 0 0;
	margin-top: 50px;
}

.modContactBlock01 .modContactBlock01In .detail .siteTitle {
	font-size: 18px;
	font-size: 1.8rem;
}

.modContactBlock01 .modContactBlock01In .detail .tel {
	font-size: 18px;
	font-size: 1.8rem;
	display: block;
}

.modContactBlock01 .modContactBlock01In .detail .tel .free {
	font-size: 26px;
	font-size: 2.6rem;
	display: block;
}

.modContactBlock01 .modContactBlock01In .detail .tel .pause {
	display: none;
}

.modBgBlock02 {
	padding: 30px 20px;
}

ul.modList02.listFloat li {
	float: none;
	width: auto;
	margin-right: 0;
}

ul.modList02.listFloat li:nth-child(4n) {
	float: none;
}

ul.modList02.listFloat li:nth-child(even) {
	float: none;
}

.modDlForm dd .parts.post .hyphen,
.modDlForm dd .parts.tel .hyphen {
	padding: 10px 5px 0 5px;
}

.modDlForm dd .parts.password input {
	max-width: none;
}

.modColumnSp_1n > li {
	float: none;
}

.modColumnSp_2n > li {
	float: right;
	width: 48.936%;
}

.modColumnSp_2n > li:nth-child(2n+1) {
	clear: both;
	float: left;
}

.modColumnSp_3n > li {
	width: 31.17%;
	margin-left: 3.245%;
}

.modColumnSp_3n > li:nth-child(3n+1) {
	clear: both;
	margin-left: 0;
}

.modListBg {
	padding: 20px;
}

.modListStep_num {
	margin-right: 10px;
	width: 50px;
	min-height: 50px;
	padding-top: 10px;
	font-size: 16px;
	font-size: 1.6rem;
}

.modListStep_content {
	overflow: visible;
}

.modListStep_title {
	padding-top: 12px;
	font-size: 20px;
	font-size: 2rem;
}

.modListStep_txt {
	clear: both;
	padding-top: 10px;
}

.modIconList_icon {
	float: none;
	margin: 0 0 20px;
}

.modIconList_title {
	font-size: 18px;
	font-size: 1.8rem;
}

.modFormList {
	padding: 15px;
}

.modFormList .title {
	font-size: 18px;
	font-size: 1.8rem;
}

.modFormList > .item {
	display: block;
}

.modFormList > .item:not(:last-child) {
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.modFormList > .item > dt {
	width: auto;
	margin-bottom: 10px;
}

.modFormList > .item > dt .required {
	top: 0;
	right: 0;
	width: 50px;
	height: 25px;
	line-height: 25px;
}

.modFormList > .item > dt .sub {
	font-size: 12px;
	font-size: 1.2rem;
}

.modFormCalendar .btnCalendar {
	background-size: 20px 22px;
}

.modFormZip {
	justify-content: space-between;
}

.modFormZip .input {
	width: 60%;
}

.modFormZip .btn {
	width: auto;
	flex: 1;
	padding-left: 10px;
}

.modFormZip .btn .modBtn01 {
	font-size: 14px;
	font-size: 1.4rem;
}

.modFormBirthday {
	justify-content: space-between;
}

.modFormBirthday .year {
	width: 30%;
}

.modFormBirthday .month,
.modFormBirthday .date {
	width: 20%;
}

.modFormBirthday .txt {
	width: 10%;
	padding: 0 5px;
	text-align: center;
}

.modFormBirthday .txt + .input {
	margin-left: 0;
}

.modFormConfBlock {
	border-radius: 15px;
	border-width: 5px;
	padding: 15px;
	margin-bottom: 50px;
}

.modFormConfBlock .wrapper {
	padding-right: 0;
	padding-left: 0;
}

.modFormConfBlock .wrapper + .wrapper {
	padding-top: 15px;
	margin-top: 20px;
}

.modFormConfBlock .wrapper .confTitle {
	margin-bottom: 15px;
	font-size: 18px;
	font-size: 1.8rem;
}

.modFormConfBlock table {
	display: block;
}

.modFormConfBlock table tbody,
.modFormConfBlock table tr,
.modFormConfBlock table th,
.modFormConfBlock table td {
	display: block;
}

.modFormConfBlock table th {
	width: auto;
}

.modFormConfBlock .table2 th {
	width: auto;
}

.resultsLabel {
	font-size: 16px;
	font-size: 1.6rem;
}

}

