@charset "utf-8";

/*
#overview
modTitle
*/

/*
#styleguide
.modTitlePage

```
<header class="modTitlePage">
	<div class="container">
		<h1 class="title">お問い合わせ</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_data">
	<div class="container">
		<h1 class="title">データ送信</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_service">
	<div class="container">
		<h1 class="title"><span class="small">オールインワン議場設備制御システム</span> DiscussBox</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_about">
	<div class="container">
		<h1 class="title">会社案内</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_privacypolicy">
	<div class="container">
		<h1 class="title">個人情報の取り扱いについて</h1>
	</div>
</header>
<header class="modTitlePage modTitlePage_policy">
	<div class="container">
		<h1 class="title">利用規約</h1>
	</div>
</header>
```
*/

.modTitlePage{
	background-color: nth($mainColor,1);
	background-position: right center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	padding: 80px 0;
	color:#fff;
	@include sphoneP{
		padding: 6vh 0;
	}

	&_recruit{
		background-image: url(../img/recruit/bg_title.jpg);
	}

	&_data{
		background-image: url(../img/data/bg_title.jpg);
	}

	&_contact{
		background-image: url(../img/contact/bg_title.jpg);
	}

	&_service{
		background-image: url(../img/service/bg_title.jpg);
	}

	&_about{
		background-image: url(../img/about/bg_title.jpg);
	}

	&_privacypolicy{
		background-image: url(../img/privacypolicy/bg_title.jpg);
	}

	&_policy{
		background-image: url(../img/policy/bg_title.jpg);
	}

	.title{
		@include fz(36);
		font-weight: 500;
		@include sphoneP{
			@include fz(22);
		}
	}
	.small{
		@include fz(28);
		@include sphoneP{
			@include fz(18);
		}
	}

}//.modTitlePage



/*
#styleguide
.modTitlePage02

```
<header class="modTitlePage02">
	<div class="container">
		<h1 class="title">お問い合わせ</h1>
	</div>
</header>
```
*/
.modTitlePage02{
	background-color: nth($mainColor,1);
	padding: 35px 0;
	text-align: center;
	color:#fff;
	@include sphoneP{
		padding: 6vh 0;
	}
	.title{
		@include fz(36);
		font-weight: 500;
		@include sphoneP{
			@include fz(22);
		}
	}
}//.modTitlePage02


/*
#styleguide
.modTitle01

```
<h2 class="modTitle01">お問い合わせフォーム</h2>
<h3 class="modTitle01 modTitle01_small">DiscussBoxの主な機能</h3>
```
*/
.modTitle01{
	position: relative;
	margin-bottom: 50px;
	text-align: center;
	font-weight: bold;
	@include fz(36);
	@include sphoneP{
		@include fz(22);
	}
	
	&_small{
		@include fz(28);
		@include sphoneP{
			@include fz(20);
		}
	}

	&:after{
		content:"";
		width:100px;
		height: 3px;
		background-color: nth($mainColor,2);
		position: absolute;
		bottom:-20px;
		left:0;
		right: 0;
		margin: auto;
	}

	&:before{
		content:"";
		position: absolute;
		bottom: -36px;
		right: 0;
		left: 0;
		height: 0;
		width: 0px;
		margin: auto;
		border: 6px solid transparent;
		border-top: 12px solid nth($mainColor,2);

	}

}//.modTitle01

/*
#styleguide
.modTitle02

```
<h3 class="modTitle02">文字起こしってどんな仕事？</h3>
<h3 class="modTitle02"><span class="smallTitle">小テキスト</span>文字起こしってどんな仕事？</h3>
<h3 class="modTitle02 modTitle02Color">文字色白</h3>
<h3 class="modTitle02_Small">文字サイズ26px</h3>
<h3 class="modTitle02_Small fontSize18">文字サイズ18px</h3>
```
*/
.modTitle02{
	margin-bottom: 10px;
	@include fz(30);
	font-weight: bold;
	line-height:1.3;
	@include sphoneP{
		@include fz(18);
	}
	.smallTitle{
		@include fz(18);
		display:block;
		margin-bottom:5px;
		@include sphoneP{
			@include fz(15);
		}
	}
	&.modTitle02Color{
		color:#fff;
	}
	&_Small{
		margin-bottom: 15px;
		@include fz(26);
		font-weight: bold;
		line-height:1.3;
		@include sphoneP{
			@include fz(20);
		}
		&.fontSize18{
			@include fz(18);
		}
	}
}

/*
#styleguide
.modTitleStrong01

```
<p class="modTitleStrong01"><span class="smallTitle">会議録検索システム</span>DiscussNetPremium</p>
```
*/
.modTitleStrong01{
	margin-bottom: 10px;
	@include fz(30);
	font-weight: bold;
	line-height:1.3;
	color:#00376a;
	@include sphoneP{
		@include fz(18);
	}
	.smallTitle{
		@include fz(18);
		display:block;
		@include sphoneP{
			@include fz(15);
		}
	}
}



h3.modContactBlock01_title{
	text-align: center;
	@include fz(20);
	
	margin-bottom: 30px;
	span{
		border-bottom: solid 3px #fff;
		display: inline-block;
	}
	@include sphoneP{
		margin-bottom: 0px;
	}
}